import { useState, useMemo, useEffect } from "react";
import { useTranslation } from "react-i18next";

export const AdvancedSearch = ({
  closeAdvancedSearchForm,
  vehicleType,
  handleAdvancedSearch,
}) => {
  const [location, setLocation] = useState(null);
  const [entryDate, setEntryDate] = useState(null);
  const [exitDate, setExitDate] = useState(null);
  const { t } = useTranslation();

  const [trailerFilters, setTrailerFilters] = useState({
    customer: null,
    trailer_number: null,
    is_loaded: null,
  });

  const [truckFilters, setTruckFilters] = useState({
    truck_number: null,
    carrier: null,
    driver_first_name: null,
    driver_last_name: null,
  });

  const [visitorFilters, setVisitorFilters] = useState({
    first_name: null,
    last_name: null,
  });

  const handleFilterChange = (setFilters, key, value) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [key]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (vehicleType === "backlog trailer") {
      const filters = {
        ...trailerFilters,
        location: location,
        entryDate: entryDate,
        exitDate: exitDate,
      };
      handleAdvancedSearch(filters, vehicleType);
    } else if (vehicleType === "backlog truck") {
      const filters = {
        ...truckFilters,
        location: location,
        entryDate: entryDate,
        exitDate: exitDate,
      };
      handleAdvancedSearch(filters, vehicleType);
    } else if (vehicleType === "backlog visitor") {
      const filters = {
        location: location,
        entryDate: entryDate,
        exitDate: exitDate,
      };
      handleAdvancedSearch(filters, vehicleType);
    }
    closeAdvancedSearchForm();
  };

  return (
    <>
      <div className="page-mask page-mask-animation">
        <div style={{ marginLeft: "188px" }} className="form-cont">
          <h2 className="global-h2 light-black">
            {t("forms.type.advanced-search.header")}
          </h2>
          <h4 className="global-h4 gray bottom-space">
            {t("forms.type.advanced-search.sub-header")}
          </h4>
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label htmlFor="location" className="input-label">
                {t("yard-tables.search-filters.dropdowns.location")}
              </label>
              <div className="input-cont">
                <select
                  className="text-input"
                  type="text"
                  id="location"
                  name="location"
                  value={location}
                  onChange={(e) => setLocation(e.target.value)}
                >
                  <option value=""></option>
                  <option value="San Diego Yard">{t("yards.sd")}</option>
                  <option value="Tijuana Yard">{t("yards.tj")}</option>
                </select>
              </div>
            </div>

            {/* The following inputs only display for trailer searching (Customer, trailer number, and content status) */}
            {vehicleType === "backlog trailer" ? (
              <div>
                <div className="form-group">
                  <label htmlFor="customer" className="input-label">
                    {t("yard-tables.search-filters.dropdowns.customer")}
                  </label>
                  <input
                    className="text-input"
                    type="text"
                    name="customer"
                    id="customer"
                    value={trailerFilters.customer}
                    onChange={(e) =>
                      handleFilterChange(
                        setTrailerFilters,
                        "customer",
                        e.target.value
                      )
                    }
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="trailer_number">
                    {t("forms.type.vehicle.input-headers.trailer-number")}
                  </label>
                  <input
                    className="text-input"
                    type="text"
                    name="trailer_number"
                    id="trailer_number"
                    value={trailerFilters.trailer_number}
                    onChange={(e) =>
                      handleFilterChange(
                        setTrailerFilters,
                        "trailer_number",
                        e.target.value
                      )
                    }
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="loaded">
                    {t("yard-tables.search-filters.dropdowns.contents")}
                  </label>
                  <div className="input-cont">
                    <select
                      className="select-input"
                      id="content"
                      value={trailerFilters.is_loaded}
                      onChange={(e) =>
                        handleFilterChange(
                          setTrailerFilters,
                          "is_loaded",
                          e.target.value
                        )
                      }
                    >
                      <option value=""></option>
                      <option value={true}>
                        {t("forms.type.vehicle.placeholders.loaded")}
                      </option>
                      <option value={false}>
                        {t("forms.type.vehicle.placeholders.empty")}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
            ) : null}

            {/* The following are inputs for trucks (truck number, carrier, license plate, driver name) */}
            {vehicleType === "backlog truck" ? (
              <div>
                <div className="form-group">
                  <label htmlFor="truck_number">
                    {t("forms.type.vehicle.input-headers.truck-number")}
                  </label>
                  <div className="input-cont">
                    <input
                      className="text-input"
                      type="text"
                      name="truck_number"
                      id="truck_number"
                      value={truckFilters.truck_number}
                      onChange={(e) =>
                        handleFilterChange(
                          setTruckFilters,
                          "truck_number",
                          e.target.value
                        )
                      }
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label htmlFor="truck_number">
                    {t("yard-tables.search-filters.dropdowns.carrier")}
                  </label>
                  <div className="input-cont">
                    <input
                      className="text-input"
                      type="text"
                      name="carrier"
                      id="carrier"
                      value={truckFilters.carrier}
                      onChange={(e) =>
                        handleFilterChange(
                          setTruckFilters,
                          "carrier",
                          e.target.value
                        )
                      }
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label htmlFor="driver_first_name">
                    {t("yard-tables.search-filters.dropdowns.driver")}
                  </label>
                  <div className="input-cont">
                    <input
                      className="text-input"
                      type="text"
                      name="driver_first_name"
                      id="driver_first_name"
                      placeholder={t(
                        "forms.type.newDriver.input-headers.firstName"
                      )}
                      value={truckFilters.driver_first_name}
                      onChange={(e) =>
                        handleFilterChange(
                          setTruckFilters,
                          "driver_first_name",
                          e.target.value
                        )
                      }
                    />
                    <input
                      className="text-input"
                      type="text"
                      name="driver_last_name"
                      id="driver_last_name"
                      placeholder={t(
                        "forms.type.newDriver.input-headers.lastName"
                      )}
                      value={truckFilters.driver_last_name}
                      onChange={(e) =>
                        handleFilterChange(
                          setTruckFilters,
                          "driver_last_name",
                          e.target.value
                        )
                      }
                    />
                  </div>
                </div>
              </div>
            ) : null}

            {vehicleType === "backlog visitor" ? (
              <div>
                <div className="form-group">
                  <label htmlFor="first_name">
                    {t("driversTable.columns.name")}
                  </label>
                  <div className="input-cont">
                    <input
                      className="text-input"
                      type="text"
                      name="first_name"
                      id="first_name"
                      placeholder={t(
                        "forms.type.newDriver.input-headers.firstName"
                      )}
                      value={visitorFilters.first_name}
                      onChange={(e) =>
                        handleFilterChange(
                          setVisitorFilters,
                          "first_name",
                          e.target.value
                        )
                      }
                    />
                    <input
                      className="text-input"
                      type="text"
                      name="last_name"
                      id="last_name"
                      placeholder={t(
                        "forms.type.newDriver.input-headers.lastName"
                      )}
                      value={visitorFilters.last_name}
                      onChange={(e) =>
                        handleFilterChange(
                          setVisitorFilters,
                          "last_name",
                          e.target.value
                        )
                      }
                    />
                  </div>
                </div>
              </div>
            ) : null}
            <div className="form-group">
              <label>{t("forms.type.advanced-search.entry-date")}</label>
              <div className="input-cont">
                <input
                  type="date"
                  className="date-input"
                  value={entryDate}
                  onChange={(e) => setEntryDate(e.target.value)}
                />
              </div>
            </div>
            <div className="form-group">
              <label>{t("forms.type.advanced-search.exit-date")}</label>
              <div className="input-cont">
                <input
                  type="date"
                  className="date-input"
                  value={exitDate}
                  onChange={(e) => setExitDate(e.target.value)}
                />
              </div>
            </div>
            <div className="full-cont">
              <button
                type="submit"
                className="change-pwd-btn"
                id="continue-btn"
              >
                {t("yard-tables.search-bar")}
              </button>
              <button className="cancel-btn" onClick={closeAdvancedSearchForm}>
                {t("buttons.close")}
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export const AdvancedSearchCtpat = ({
  close,
  handleFilterChange,
  filters,
  handleSearch,
}) => {
  const { t } = useTranslation();
  return (
    <>
      <div className="page-mask page-mask-animation">
        <div className="edit-modal">
          <h2 className="global-h2 light-black">
            {t("forms.type.advanced-search.header")}
          </h2>
          <h4 className="global-h4 gray bottom-space">
            {t("forms.type.advanced-search.sub-header")}
          </h4>
          <div>
            <div className="form-group">
              <label htmlFor="trailer_number">
                {t("forms.type.vehicle.input-headers.trailer-number")}
              </label>
              <div className="input-cont">
                <input
                  className="text-input"
                  type="text"
                  name="trailer_number"
                  id="trailer_number"
                  onChange={(e) =>
                    handleFilterChange("trailer_number", e.target.value)
                  }
                  value={filters.trailer_number}
                />
              </div>
            </div>
            <div className="form-group">
              <label htmlFor="carrier">
                {t("yard-tables.search-filters.dropdowns.carrier")}
              </label>
              <div className="input-cont">
                <input
                  className="text-input"
                  type="text"
                  name="carrier"
                  id="carrier"
                  onChange={(e) =>
                    handleFilterChange("carrier", e.target.value)
                  }
                  value={filters.carrier}
                />
              </div>
            </div>
            <div className="form-group">
              <label htmlFor="truck_number">
                {t("forms.type.vehicle.input-headers.truck-number")}
              </label>
              <div className="input-cont">
                <input
                  className="text-input"
                  type="text"
                  name="truck_number"
                  id="truck_number"
                  onChange={(e) =>
                    handleFilterChange("truck_number", e.target.value)
                  }
                  value={filters.truck_number}
                />
              </div>
            </div>
            <div className="form-group">
              <label>{t("forms.type.advanced-search.from")}</label>
              <div className="input-cont">
                <input
                  type="date"
                  className="date-input"
                  onChange={(e) =>
                    handleFilterChange("from_date", e.target.value)
                  }
                  value={filters.from_date}
                />
              </div>
            </div>
            <div className="form-group">
              <label>{t("forms.type.advanced-search.to")}</label>
              <div className="input-cont">
                <input
                  type="date"
                  className="date-input"
                  onChange={(e) =>
                    handleFilterChange("to_date", e.target.value)
                  }
                  value={filters.to_date}
                />
              </div>
            </div>
            <div className="form-group">
              <label>{t("forms.type.advanced-search.event")}</label>
              <div className="input-cont">
                <select
                  className="select-input"
                  value={filters.check_type}
                  onChange={(e) =>
                    handleFilterChange("check_type", e.target.value)
                  }
                >
                  <option value="">
                    {t("forms.type.advanced-search.all")}
                  </option>
                  <option value={true}>
                    {t("forms.type.advanced-search.entry")}
                  </option>
                  <option value={false}>
                    {t("forms.type.advanced-search.exit")}
                  </option>
                </select>
              </div>
            </div>
            <div className="full-cont">
              <button className="change-pwd-btn" onClick={handleSearch}>
                {t("yard-tables.search-bar")}
              </button>
              <button className="cancel-btn" onClick={() => close(false)}>
                {t("buttons.close")}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export const MechanicReportSearch = ({
  close,
  handleFilterChange,
  filters,
  handleSearch,
  type,
}) => {
  const t = useTranslation();
  return (
    <div className="page-mask page-mask-animation">
      <div className="form-cont">
        <h2 className="global-h2 light-black">
          {t("forms.type.advanced-search.header")}
        </h2>
        <h4 className="global-h4 gray bottom-space">
          {t("forms.type.advanced-search.sub-header")}
        </h4>
      </div>
    </div>
  );
};
