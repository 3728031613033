import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import instance from "../../Interceptor/api_instance";
import { Spinner } from "../LoadingAnimations/LoadingAnimations";
import SearchBar from "../Inputs/SearchBar";
import { UpArrow, DownArrow } from "../../Media/Icons";
import { sortEmployees } from "../Tables/Sorting/functions";
import { PageLayout } from "../PageLayout";

const ResponseModal = ({ english, message, close }) => {
  return (
    <div className="page-mask page-mask-animation">
      <div className="blank-card active-confirm">
        <h4>{message}</h4>

        <div className="full-cont">
          <button className="cancel-btn" onClick={close}>
            {english("buttons.close")}
          </button>
        </div>
      </div>
    </div>
  );
};

export const NewEmployee = () => {
  const { t } = useTranslation();
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    title: "",
    employeePin: "",
  });
  const headers = [
    { name: "Employee", filter: "employeeName" },
    { name: "Title", filter: "jobTitle" },
    { name: "Pin", filter: "pin" },
  ];

  const [order, setOrder] = useState(1);
  const [employeeData, setEmployeeData] = useState([]);
  const [handlingRequest, setHandlingRequest] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [successModal, setSuccessModal] = useState(false);

  const transformData = (data) => {
    const transformedData = data.map((item) => {
      const newData = {
        employeeName: `${item.first_name} ${item.last_name}`,
        jobTitle: item.job_title,
        employeeId: item.employee_pin,
        pin: item.employee_pin,
      };
      return newData;
    });
    transformedData.sort((a, b) => (a.employeeName > b.employeeName ? 1 : -1));
    return transformedData;
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await instance.get("safety/get-employees");
        if (response.status === 200) {
          setEmployeeData(transformData(response.data));
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handlePinChange = (e) => {
    const value = e.target.value;
    // Allow only numbers and restrict length to 6 digits
    if (/^\d*$/.test(value) && value.length <= 3) {
      setFormData((prevData) => ({
        ...prevData,
        employeePin: value,
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setHandlingRequest(true);
      const response = await instance.post("safety/create-employee", formData);
      if (response.status === 201) {
        setSuccessModal(true);
      }
    } catch (error) {
      setErrorMsg(error);
    } finally {
      setHandlingRequest(false);
    }
  };

  const generatePin = () => {
    let pin = null;
    do {
      pin = Math.floor(Math.random() * (999 - 100 + 1)) + 100;
    } while (employeeData.includes((item) => item.employee_pin === pin));

    setFormData((prevData) => ({
      ...prevData,
      employeePin: pin,
    }));
  };
  return (
    <PageLayout title={"New Employee"}>
      {errorMsg && (
        <ResponseModal
          english={t}
          message={errorMsg}
          close={() => setErrorMsg(false)}
        />
      )}
      {successModal && (
        <ResponseModal
          english={t}
          message={t("graphics-cont.confirm-modal.employee-created")}
          close={() => window.location.reload()}
        />
      )}
      <div className="inventory-header">
        <h1 className="global-h1 black">{t("page-headers.new-employee")}</h1>
      </div>
      <div className="add-new-driver-cont">
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label className="input-label required">
              {t("forms.type.newDriver.input-headers.firstName")}
            </label>
            <div className="input-cont">
              <input
                name="firstName"
                value={formData.firstName}
                className="text-input"
                onChange={handleInputChange}
                type="text"
                placeholder={t("forms.type.newDriver.input-headers.firstName")}
                required
              />
            </div>
          </div>
          <div className="form-group">
            <label className="input-label required">
              {t("forms.type.newDriver.input-headers.lastName")}
            </label>
            <div className="input-cont">
              <input
                name="lastName"
                value={formData.lastName}
                onChange={handleInputChange}
                className="text-input"
                type="text"
                placeholder={t("forms.type.newDriver.input-headers.lastName")}
                required
              />
            </div>
          </div>
          <div className="form-group">
            <label className="input-label required">
              {t("yard-tables.table-columns.title")}
            </label>
            <div className="input-cont">
              <select
                name="title"
                onChange={handleInputChange}
                value={formData.title}
                required
              >
                <option value="" disabled>{t("yard-tables.table-columns.title")}</option>
                <option value="Guard">
                  {t("yard-tables.table-columns.guard")}
                </option>
                <option value="Mechanic">
                  {t("yard-tables.table-columns.mechanic")}
                </option>
              </select>
            </div>
          </div>
          <div className="form-group">
            <label className="input-label required">Pin</label>
            <div className="input-cont input-cont-message">
              <div style={{ display: "flex", gap: "12px" }}>
                <input
                  name="pin"
                  value={formData.employeePin}
                  onChange={handlePinChange}
                  className="text-input"
                  type="text"
                  placeholder="123"
                  required
                />
                <button type="button" onClick={generatePin} className="all-btn">
                  {t("buttons.generate")}
                </button>
              </div>
              {employeeData.some(
                (item) => item.pin === parseInt(formData.employeePin)
              ) ? (
                <p className="error">{t("errors.pin-exists")}</p>
              ) : null}
            </div>
          </div>
          {handlingRequest ? (
            <Spinner />
          ) : (
            <button className="change-pwd-btn" type="submit">
              {t("buttons.submit")}
            </button>
          )}
        </form>
      </div>
      <div className="search-filters-cont">
        <SearchBar />
      </div>
      <div className="table-cont">
        <table id="forwardTable" className="yard-table">
          <thead>
            <tr className="table-header">
              {headers?.map((item, index) => (
                <th key={index} scope="col">
                  <button
                    onClick={() =>
                      sortEmployees(
                        employeeData,
                        item.filter,
                        setEmployeeData,
                        order,
                        setOrder
                      )
                    }
                    className="col-header"
                  >
                    {t(`yard-tables.table-columns.${item.name.toLowerCase()}`)}
                    <div className="order-filter">
                      <UpArrow />
                      <DownArrow />
                    </div>
                  </button>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {employeeData?.map((item, index) => (
              <tr key={index} className="data-rows">
                <td key={`name-${index}`} className="gray">
                  {item.employeeName}
                </td>
                <td key={`position-${index}`} className="gray">
                  {t(
                    `yard-tables.table-columns.${item.jobTitle.toLowerCase()}`
                  )}
                </td>
                <td key={`pin-${index}`} className="gray">
                  {item.pin}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </PageLayout>
  );
};
