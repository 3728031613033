import { convertToPST } from "../../Helper/FormatFunctions";

export const MechanicRowModal = ({ close, english, row }) => {
  return (
    <div className="view-report-modal">
      <div>
        <h4 data-testid="employee" className="gloval-h2">
          Reporte
        </h4>
      </div>
      <div className="report-item-row">
        <div className="report-item">
          <label>Employee</label>
          <p>
            {row.employee.first_name} {row.employee.last_name}
          </p>
        </div>
        <div className="report-item">
          <label>Date Time</label>
          <p>{convertToPST(row.created_at)}</p>
        </div>
      </div>
      <div className="report-item-row">
        <div className="report-item">
          <label>Trailer</label>
          <p>{row.trailer ? row.trailer.trailer_number : "None"}</p>
        </div>
        <div className="report-item">
          <label>Truck</label>
          <p>
            {row.truck
              ? `${row.truck.carrier_name}-${row.truck.truck_number}`
              : "None"}
          </p>
        </div>
      </div>
      <div className="report-item-row">
        <div className="report-item-description">
          <label>Description</label>
          <p>{row.description}</p>
        </div>
      </div>
      <div className="report-item-row">
        <div className="report-item-description">
          <label>Notes</label>
          <p>{row.notes}</p>
        </div>
      </div>
      <div className="btn-cont">
        <button onClick={close} className="close-btn">
          {english("buttons.close")}
        </button>
      </div>
    </div>
  );
};
