import { Link } from "react-router-dom";
import React from "react";
import { IsRouteActive } from "./NavFunctions";
import { useAuth } from "../../Auth/AuthProvider";
import { useWindowWidth } from "../Utility/useWindowWidth";

export const SubNavRoute = ({ route, text, user }) => {
  const { openSideBar } = useAuth();
  const width = useWindowWidth();
  const isMobile = width <= 500;
  return (
    <>
      <li
        className={
          IsRouteActive(route) ? "sub-menu-item highlight" : "sub-menu-item"
        }
      >
        <div></div>
        <Link
          className={
            IsRouteActive(route)
              ? "active-span active"
              : user.theme === "System"
              ? "s-inactive inactive"
              : "a-inactive inactive"
          }
          to={route}
          onClick={isMobile ? openSideBar : () => {}}
        >
          <span className="route-name">{text}</span>
        </Link>
      </li>
    </>
  );
};

export const MobileSubNavRoute = ({ route, text }) => {
  return (
    <>
      <li
        className={
          IsRouteActive(route)
            ? "mobile-sub-menu-item highlight"
            : "mobile-sub-menu-item"
        }
      >
        <div></div>
        <Link
          className={
            IsRouteActive(route)
              ? "active-span mobile active"
              : "s-inactive inactive mobile"
          }
          to={route}
        >
          <span className="route-name">{text}</span>
        </Link>
      </li>
    </>
  );
};
