import { Helmet } from "react-helmet";
import { useAuth } from "../Auth/AuthProvider";
import { useWindowWidth } from "./Utility/useWindowWidth";

const routes = ["Check In", "Check Out"]
export const PageLayout = ({ title, children }) => {
  const { isSideOpen } = useAuth();
  const width = useWindowWidth();
  const isTablet = (width <= 1300 && width > 500)
  return (
    <div className="global-cont">
      <Helmet>
        <title>Forward | {title}</title>
      </Helmet>
      <div
        className={`page-overview ${
          isSideOpen && isTablet ? "shift" : ""
        }`}
      >
        <div className={`${!routes.includes(title) ? "cont-wrapper" : ""}`}>{children}</div>
      </div>
    </div>
  );
};
