import { useState, useEffect, useMemo } from "react";
import TableImgModal from "../Modals/TableImgModal";
import { RowClickModal, EditRowModal } from "../Modals/RowClickModal";
import { AdvancedSearch } from "../Utility/AdvancedSearch";

import { formatPhoneNumber } from "../../Helper/FormatFunctions.js";

import { UpArrow, DownArrow, CheckOutIcon } from "../../Media/Icons";
import Switch from "../Inputs/Switch";
import FancyFilter from "./Sorting/fancyFilter";
import { Carrot } from "../../Media/Icons";
import { searchQuery } from "../../Interceptor/csrftoken";
import SearchBar from "../Inputs/SearchBar";
import { getDuration } from "../../Interceptor/csrftoken";

import { TablePlaceHolder } from "../LoadingAnimations/LoadingAnimations";
import { useWindowWidth } from "../Utility/useWindowWidth";
import { EditCondition } from "../Modals/EditCondition.js";

/**
 * UniversalTable Component
 *
 * @component
 * The `UniversalTable` function in JavaScript is a versatile component that renders a table with
 * dynamic data, headers, filters, sorting, row selection, and various functionalities based on the
 * provided props.
 * @returns  It renders a table based on the provided props such as type, data, headers, filters, and various other state variables. The table displays
 * data rows with columns based on the headers provided. It also includes sorting functionality,
 * filtering options, row click events, and modal popups for additional information or actions.
 *
 * @prop {string} type - The type of data the table will be displaying.
 * @prop {Array} data - The data passed to the table.
 * @prop {Array} headers - Header names based on the keys of the data to be displayed in each column.
 * @prop {function} english - Function to translate text to English.
 * @prop {Array} filters - List of filters to be displayed at the top of the table.
 * @prop {function} onRowSelect - Handler function for row selection. See driver table for functionality.
 * @prop {boolean} isDriverInfoOpen - Checks if the driver information modal is open.
 * @prop {function} ActiveConfirmModal - Modal to activate or deactivate a driver.
 * @prop {function} setIsConfirmModal - Sets the confirm modal state in the child component.
 * @prop {boolean} isConfirmModal - Checks if the activate driver modal is open or closed.
 * @prop {function} handleForm - Opens form on the guard table to check out a truck.
 * @prop {string} error - Displays any errors on the table.
 * @prop {Object} user - User object containing user information.
 * @prop {number} page - Displays the current page if the table is paginated.
 * @prop {function} handleNextPage - Handles navigation to the next page in a paginated table.
 * @prop {function} handlePreviousPage - Handles navigation to the previous page in a paginated table.
 * @prop {number} totalPages - Total number of pages in a paginated table.
 * @prop {boolean} pageLoading - Indicates the loading state of a backlog page.
 * @prop {function} handlePageChange - Handles page changes in paginated tables.
 *
 * @example - taken from the drivingTeam
 * <UniversalTable
 *     type={"driver"}
 *     english={t}
 *     data={driverData}
 *     headers={[
 *       "id",
 *       "is_active",
 *       "first_name",
 *       "carrier_name",
 *       "license_number",
 *       "personal_phone",
 *       "work_phone",
 *       "email",
 *     ]}
 *     filters={["Carrier"]}
 *     onRowSelect={handleRowSelect}
 *     isDriverInfoOpen={isModalOpen}
 *     ActiveConfirmModal={ActiveConfirmModal}
 *     isConfirmModal={isConfirmModal}
 *     error={error}
 *     pageLoading={isLoading}
 * />
 *
 *
 *
 */
export default function UniversalTable({
  type,
  data,
  headers,
  english,
  filters,
  onRowSelect,
  isDriverInfoOpen,
  ActiveConfirmModal,
  setIsConfirmModal,
  isConfirmModal,
  handleForm,
  error,
  user,
  pageLoading,
  ReservationConfirm,
  reservationModal,
  setReservationModal,
  advancedSearch,
  handleAdvancedSearch,
}) {
  const [sortDirection, setSortDirection] = useState("asc");
  const [sortKey, setSortKey] = useState(null);

  // row click state
  const [selectedRow, setSelectedRow] = useState(null);
  const [selectedReservation, setSelectedReservation] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDocuments, setIsDocumnets] = useState(false);
  const [isEditRow, setIsEditRow] = useState(false);
  const [expandedRow, setExpandedRow] = useState(null);

  // states for the filters
  const [allCustomers, setAllCustomers] = useState([]);
  const [allLocations, setAllLocations] = useState([]);
  const [allContents, setAllContents] = useState([]);
  const [allReservations, setAllReservations] = useState([]);
  const [AllCarriers, setAllCarriers] = useState([]);
  const [allDrivers, setAllDrivers] = useState([]);
  const [allDurations, setAllDurations] = useState([]);
  const [allDestinations, setAllDestinations] = useState([]);
  const [allReservationTimes, setAllReservationTimes] = useState([]);

  const [selectedCustomers, setSelectedCustomers] = useState([]);
  const [selectedLocations, setSelectedLocations] = useState([]);
  const [selectedReservations, setSelectedReservations] = useState([]);
  const [selectedContents, setSelectedContents] = useState([]);
  const [selectedCarrier, setSelectedCarrier] = useState([]);
  const [selectedDriver, setSelectedDriver] = useState([]);
  const [selectedDurations, setSelectedDurations] = useState([]);
  const [selectedDestinations, setSelectedDestinations] = useState([]);

  const [advancedSearchForm, setAdvancedSearchForm] = useState(false);

  // State to keep track of the currently open dropdown
  const [openDropdown, setOpenDropdown] = useState(null);

  // Driver carrot state
  const [carrot, setCarrot] = useState(false);
  const [table, setTable] = useState("active");

  //loading filter count
  const loadingFilters = filters?.length;

  // Handler to toggle the open dropdown
  const toggleDropdown = (dropdownId) => {
    setOpenDropdown(openDropdown === dropdownId ? null : dropdownId);
  };

  const openAdvancedSearchForm = () => {
    setAdvancedSearchForm(true);
  };

  const closeAdvancedSearchForm = () => {
    setAdvancedSearchForm(false);
  };

  // custom hook to calculate window width
  const width = useWindowWidth();
  const isMobile = width <= 500;

  // Column sort functions -----------------------------------------------------
  const handleSort = (key) => {
    if (sortKey === key) {
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      setSortKey(key);
      setSortDirection("asc");
    }
  };

  // function to handle asc/desc filters (header click) -----------------------------------------------------
  const sortedData = (data) => {
    if (sortKey) {
      return data.sort((a, b) => {
        const valA = getNestedProperty(a, sortKey);
        const valB = getNestedProperty(b, sortKey);

        // Handle null values
        if (valA === null && valB === null) return 0;
        if (valA === null) return sortDirection === "asc" ? 1 : -1;
        if (valB === null) return sortDirection === "asc" ? -1 : 1;

        // Check if both values are numeric
        const isANumeric = !isNaN(parseFloat(valA)) && isFinite(valA);
        const isBNumeric = !isNaN(parseFloat(valB)) && isFinite(valB);

        let valueA = valA;
        let valueB = valB;

        // If both values are numeric, compare as numbers
        if (isANumeric && isBNumeric) {
          valueA = Number(valA);
          valueB = Number(valB);
        }

        // String comparison if they are not both numbers
        if (valueA < valueB) return sortDirection === "asc" ? -1 : 1;
        if (valueA > valueB) return sortDirection === "asc" ? 1 : -1;
        return 0;
      });
    }
    return data;
  };

  // Takes in the table row object and header name to access the correct data in each column ---------------------
  const getNestedProperty = (obj, path) => {
    return path.split(".").reduce((acc, key) => acc && acc[key], obj);
  };

  // function to render cell contents based on header type -----------------------------------------------------
  const renderCellContent = (item, header) => {
    if (
      header.includes("check_in_dt") ||
      header.includes("check_out_dt") ||
      header.includes("event_time") ||
      header.includes("created_at")
    ) {
      const dataKey = header.includes("created_at")
        ? item.created_at
        : getNestedProperty(item, header);
      return new Date(dataKey).toLocaleDateString("en-US", {
        year: "numeric",
        month: "long",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
        timeZone: "America/Los_Angeles",
      });
    }
    if (header.includes("is_loaded")) {
      const dataKey = getNestedProperty(item, header);
      return dataKey
        ? english("forms.type.vehicle.placeholders.loaded")
        : english("forms.type.vehicle.placeholders.empty");
    }
    if (header.includes("customer")) {
      const dataKey = getNestedProperty(item, header);
      return dataKey
        ? dataKey
        : english("forms.type.vehicle.placeholders.none");
    }
    if (header.includes("seal_number")) {
      header = item.trailer.check_out_dt ? "trailer.check_out_seal_number" : "trailer.seal_number";
      const dataKey = getNestedProperty(item, header);
      return dataKey
        ? dataKey
        : english("forms.type.vehicle.placeholders.none");
    }

    switch (header) {
      case "Duration":
        return getDuration(
          item.trailer ? item.trailer.check_in_dt : item.truck.check_in_dt
        );
      case "repair_duration_trailer":
        return getDuration(item.trailer.trailer_condition_last_update);
      case "trailer_condition_notes":
        return item.trailer.trailer_condition_notes;
      case "truck_condition_notes":
        return item.truck.truck_condition_notes;
      case "repair_duration_truck":
        return getDuration(item.truck.truck_condition_last_update);
      case "first_name":
        return `${item.first_name} ${item.last_name}`;
      case "visitor.first_name":
        return `${item.visitor.first_name} ${item.visitor.last_name}`;
      case "visitor.check_in_dt":
        return item.visitor.check_in_dt;
      case "visitor.check_out_dt":
        return item.visitor.check_out_dt;
      case "user.first_name":
        return `${item.user.first_name} ${item.user.last_name}`;
      case "guard.first_name":
        return `${item.user.first_name} ${item.user.last_name}`;
      case "trailer.reservation":
        return (
          <span>
            {item.trailer.reservation
              ? item.trailer.reservation.user.first_name +
                " " +
                item.trailer.reservation.user.last_name
              : english("forms.type.vehicle.placeholders.none")}
          </span>
        );
      case "trailer.reservation.duration":
        return item.trailer.reservation
          ? item.trailer.reservation.reservation_time
          : english("forms.type.vehicle.placeholders.none");
      case "Check Out":
        return (
          <button onClick={() => handleForm(item)} className="icon-col-btn">
            <CheckOutIcon color={"#FF6E6E"} />
          </button>
        );
      case "reservation_status":
        return (
          <>
            {!item.trailer.reservation ? (
              item.trailer.trailer_condition === "Good" ||
              item.trailer.trailer_condition !== "Okay" ? (
                <button
                  onClick={() =>
                    handleReservationRowClick([
                      item.trailer.id,
                      english(
                        "graphics-cont.reservations.reserve"
                      ).toLowerCase(),
                      item.trailer.trailer_number,
                    ])
                  }
                  className="all-btn success-btn"
                >
                  {english("graphics-cont.reservations.reserve")}
                </button>
              ) : (
                <button disabled className="all-btn faded-success-btn">
                  {english("yard-tables.search-filters.dropdowns.reservation")}
                </button>
              )
            ) : item.trailer.reservation.user.id === user.id ? (
              <button
                onClick={() =>
                  handleReservationRowClick([
                    item.trailer.id,
                    english(
                      "graphics-cont.reservations.unreserve"
                    ).toLowerCase(),
                    item.trailer.trailer_number,
                  ])
                }
                className="all-btn error-btn"
              >
                {english("graphics-cont.reservations.unreserve")}
              </button>
            ) : (
              <button className="all-btn error-btn" disabled>
                {item.trailer.reservation.user.first_name}{" "}
                {item.trailer.reservation.user.last_name}
              </button>
            )}
          </>
        );
      case "trailer.trailer_condition":
        const condition =
          type.includes("trailer") || type.includes("reservation")
            ? item.trailer.trailer_condition
            : item.truck.truck_condition;
        return condition === "Good" ? (
          <span className="success">
            {english("forms.type.vehicle.placeholders.select-condition.good")}
          </span>
        ) : condition === "Okay" ? (
          <span className="warning">Okay</span>
        ) : condition === "Poor" ? (
          <span className="poor">
            {english("forms.type.vehicle.placeholders.select-condition.poor")}
          </span>
        ) : condition === "Critical" ? (
          <span className="critical">
            {english(
              "forms.type.vehicle.placeholders.select-condition.critical"
            )}
          </span>
        ) : condition === "Under Repair" ? (
          <span className="warning">
            {english(
              "forms.type.vehicle.placeholders.select-condition.under-repair"
            )}
          </span>
        ) : condition === "Waiting parts" ? (
          <span className="warning">
            {english(
              "forms.type.vehicle.placeholders.select-condition.spare-parts"
            )}
          </span>
        ) : condition === "Waiting instructions" ? (
          <span className="warning">
            {english(
              "forms.type.vehicle.placeholders.select-condition.instructions"
            )}
          </span>
        ) : condition === "External repair" ? (
          <span className="warning">
            {english(
              "forms.type.vehicle.placeholders.select-condition.external-repair"
            )}
          </span>
        ) : null;

      // The following cases are for Safety and activity of mechanics
      case "activity.employee_name":
        return `${item.employee.first_name} ${item.employee.last_name}`;
      case "activity.trailer":
        return item.trailer.trailer_number;
      case "activity.truck":
        return item.truck
          ? `${item.truck.carrier_name}-${item.truck.truck_number}`
          : english("forms.type.vehicle.placeholders.none");
      case "activity.category":
        return item.category;
      case "activity.description":
        return item.description;
      case "activity.created_at":
        return item.created_at;
      case "trailer_number":
        return item.trailer_number;
      case "trailer_condition":
        return item.trailer_condition;
      case "trailer_condition_notes":
        return item.trailer_condition_notes;
      case "truck_number":
        return item.truck_number;
      case "carrier_name":
        return item.carrier_name;
      case "check_in_dt":
        return item.check_in_dt;
      case "check_out_dt":
        return item.check_out_dt;
      case "carrier_name":
        return item.carrier_name;
      case "truck_condition_notes":
        return item.truck_condition_notes;
      default:
        if (header.includes("phone")) {
          return formatPhoneNumber(item[header]);
        } else {
          return getNestedProperty(item, header);
        }
    }
  };

  // Replaces data keys with column header names -----------------------------------------------------
  const headerTranslations = {
    "location.loc_name": "yard-tables.search-filters.dropdowns.location",
    "trailer.trailer_number": "yard-tables.tabs.trailer",
    Duration: "yard-tables.table-columns.duration",
    "trailer.is_loaded": "yard-tables.search-filters.dropdowns.contents",
    "trailer.trailer_condition": "yard-tables.table-columns.condition",
    "trailer.check_in_dt": "yard-tables.table-columns.arrival-time",
    check_in_dt: "yard-tables.table-columns.arrival-time",
    reservation_user_id: "yard-tables.search-filters.dropdowns.reservation",
    "trailer.seal_number": "yard-tables.table-columns.seal",
    "trailer.customer": "yard-tables.search-filters.dropdowns.customer",
    "internal_id": "EXP",
    is_active: "driversTable.tabs.active",
    "visitor.first_name": "driversTable.columns.name",
    "user.first_name": "driversTable.columns.name",
    "guard.first_name": "forms.type.visitor.purpose.guard",
    "trailer.license_plate": "driversTable.columns.license",
    personal_phone: "driversTable.columns.personalPhone",
    work_phone: "driversTable.columns.companyPhone",
    email: "driversTable.columns.email",
    "truck.truck_number": "yard-tables.tabs.truck",
    "truck.carrier_name": "yard-tables.search-filters.dropdowns.carrier",
    "trailer.reservation": "yard-tables.search-filters.dropdowns.reservation",
    driver_first_name: "yard-tables.search-filters.dropdowns.driver",
    "trailer.destination": "yard-tables.search-filters.dropdowns.destination",
    "truck.destination": "yard-tables.search-filters.dropdowns.destination",
    license_plate: "yard-tables.table-columns.license_plate",
    "Check Out": "page-headers.check-out",
    check_out_dt: "page-headers.check-out",
    reservation_status: "yard-tables.search-filters.dropdowns.reservation",
    first_name: "driversTable.columns.name",
    carrier_name: "yard-tables.search-filters.dropdowns.carrier",
    license_number: "driversTable.columns.license",
    "truck.check_in_dt": "yard-tables.table-columns.arrival-time",
    "truck.check_out_dt": "page-headers.check-out",
    "visitor.check_in_dt": "yard-tables.table-columns.arrival-time",
    "visitor.check_out_dt": "page-headers.check-out",
    "trailer.check_out_dt": "page-headers.check-out",
    event_type: "yard-tables.table-columns.event-type",
    vehicle_name: "yard-tables.table-columns.vehicle-name",
    company: "yard-tables.table-columns.company",
    event_time: "yard-tables.table-columns.event-time",
    "trailer.reservation.duration": "duration",
    "activity.employee_name": "yard-tables.table-columns.employee_name",
    "activity.trailer": "yard-tables.tabs.trailer",
    "activity.truck": "yard-tables.tabs.truck",
    "activity.description": "yard-tables.table-columns.description",
    "activity.created_at": "yard-tables.table-columns.arrival-time",
    "activity.category": "yard-tables.table-columns.category",
    repair_duration_trailer: "yard-tables.table-columns.duration",
    repair_duration_truck: "yard-tables.table-columns.duration",
    trailer_condition_notes: "forms.type.vehicle.input-headers.notes",
    truck_condition_notes: "forms.type.vehicle.input-headers.notes",
  };

  // render header translation if translation exists
  const renderHeaderContent = (header) => {
    return english(headerTranslations[header]) || header;
  };

  // row click -------------------------------------------
  const handleRowClick = (row) => {
    setSelectedRow(row);
    setIsModalOpen(true);
  };

  // reserve a trailer -------------------------------------------
  const handleReservationRowClick = (row) => {
    setSelectedReservation(row);
    setReservationModal(true);
  };

  // close row modal -----------------------------------------------------
  const closeModal = () => {
    setIsEditRow(false);
    setIsDocumnets(false);
    setIsModalOpen(false);
  };

  // get all data for the filters ---------------------------------------------
  useEffect(() => {
    const customers = new Set();
    const locations = new Set();
    const contents = new Set();
    const reservations = new Set();
    const reservationDurations = new Set();
    const carriers = new Set();
    const drivers = new Set();
    const durations = new Set();
    const destinations = new Set();

    for (let i = 0; i < data?.length; i++) {
      const item = data[i];

      if (type.includes("trailer")) {
        if (item.trailer.customer) customers.add(item.trailer.customer);

        if (item.location.loc_name) locations.add(item.location.loc_name);

        contents.add(
          item.trailer.is_loaded
            ? english("forms.type.vehicle.placeholders.loaded")
            : english("forms.type.vehicle.placeholders.empty")
        );
        if (item.trailer.is_reserved) {
          reservations.add(
            `${item.trailer.reservation.user.first_name} ${item.trailer.reservation.user.last_name}`
          );
          reservationDurations.add(item.trailer.reservation.reservation_time);
        }
      }

      if (type.includes("truck")) {
        if (item.location.loc_name) locations.add(item.location.loc_name);
        if (item.visitor.first_name) {
          drivers.add(item.visitor.first_name + " " + item.visitor.last_name);
        }
        if (item.truck.destination) {
          destinations.add(item.truck.destination);
        }
      }
      if (type.includes("driver")) {
        if (item.carrier_name) {
          carriers.add(item.carrier_name);
        }
      }
      if (type.includes("truck")) {
        if (item.truck.carrier_name) {
          carriers.add(item.truck.carrier_name);
        }
      }
    }

    setAllDestinations(Array.from(destinations));
    setAllDurations(Array.from(durations));
    setAllCustomers(Array.from(customers));
    setAllLocations(Array.from(locations));
    setAllContents(Array.from(contents));
    setAllReservations(Array.from(reservations));
    setAllCarriers(Array.from(carriers));
    setAllDrivers(Array.from(drivers));
    setAllReservationTimes(Array.from(reservationDurations));
  }, [data, type]);

  // Map filter names to state variables -----------------------------------------------------
  const filterItemsMap = {
    Location: allLocations,
    Customer: allCustomers,
    Contents: allContents,
    Reservation: allReservations,
    Carrier: AllCarriers,
    Driver: allDrivers,
    Duration: allDurations,
    Destination: allDestinations,
  };

  const filterSelectedMap = {
    Location: selectedLocations,
    Customer: selectedCustomers,
    Contents: selectedContents,
    Reservation: selectedReservations,
    Carrier: selectedCarrier,
    Driver: selectedDriver,
    Duration: selectedDurations,
    Destination: selectedDestinations,
  };
  const filterSetMap = {
    Location: setSelectedLocations,
    Customer: setSelectedCustomers,
    Contents: setSelectedContents,
    Reservation: setSelectedReservations,
    Carrier: setSelectedCarrier,
    Driver: setSelectedDriver,
    Duration: setSelectedDurations,
    Destination: setSelectedDestinations,
  };

  // filter handle function
  const handleSelect = (item, setSelectedItems, type) => {
    // Helper function to update selected items
    const updateSelectedItems = (prevSelectedItems) => {
      // Check if the item is one of the special items or "all"
      if (type === "list") {
        // Toggle selection for special items (or "all")
        return prevSelectedItems.includes(item) ? [] : [item];
      } else {
        // Handle multiple selection for other items
        return prevSelectedItems.includes(item)
          ? prevSelectedItems.filter((selectedItem) => selectedItem !== item)
          : [...prevSelectedItems, item];
      }
    };

    // Update the selected items
    setSelectedItems(updateSelectedItems);
  };

  // Filter the data based on selected filters -----------------------------------------------------
  const loadedText = english("forms.type.vehicle.placeholders.loaded");
  const emptyText = english("forms.type.vehicle.placeholders.empty");

  // Convert arrays to sets for faster lookups
  const selectedCustomersSet = new Set(selectedCustomers);
  const selectedCarrierSet = new Set(selectedCarrier);
  const selectedLocationsSet = new Set(selectedLocations);
  const selectedContentsSet = new Set(selectedContents);
  const selectedDriverSet = new Set(selectedDriver);
  const selectedDurationsSet = new Set(selectedDurations);
  const selectedDestinationsSet = new Set(selectedDestinations);
  const selectedReservationsSet = new Set(selectedReservations);

  const filteredData = data
    ? data.filter((item) => {
        if (type === "driver") {
          // For driver data
          const driverCarrier = item.carrier_name || "";

          const carrierMatch =
            selectedCarrier.length === 0 ||
            selectedCarrierSet.has(driverCarrier);

          return carrierMatch;
        } else {
          // For vehicle data (trailers/trucks)
          const isTrailer = type.includes("trailer");
          const vehicle = isTrailer ? item.trailer : item.truck;

          // Safely access properties with optional chaining
          const vehicleCustomer = vehicle?.customer || "";
          const vehicleCarrierName = vehicle?.carrier_name || "";
          const vehicleIsLoaded = vehicle?.is_loaded || false;
          const vehicleCheckInDt = vehicle?.check_in_dt || "";
          const vehicleDestination = vehicle?.destination || "";
          const vehicleReservationUser = vehicle?.reservation?.user || {};

          const contentStatus = vehicleIsLoaded ? loadedText : emptyText;
          const driverName = `${item.visitor?.first_name || ""} ${
            item.visitor?.last_name || ""
          }`.trim();
          const reservationUserName = isTrailer
            ? `${vehicleReservationUser.first_name || ""} ${
                vehicleReservationUser.last_name || ""
              }`
            : "";

          const duration = getDuration(vehicleCheckInDt);

          const customerMatch =
            selectedCustomers.length === 0 ||
            selectedCustomersSet.has(vehicleCustomer);

          const carrierMatch =
            selectedCarrier.length === 0 ||
            selectedCarrierSet.has(vehicleCarrierName);

          const locationMatch =
            selectedLocations.length === 0 ||
            selectedLocationsSet.has(item.location?.loc_name || "");

          const contentMatch =
            selectedContents.length === 0 ||
            selectedContentsSet.has(contentStatus);

          const driverMatch =
            selectedDriver.length === 0 ||
            [...selectedDriverSet].some((r) => driverName.includes(r));

          const durationMatch =
            selectedDurations.length === 0 ||
            selectedDurationsSet.has(duration);

          const destinationMatch =
            selectedDestinations.length === 0 ||
            selectedDestinationsSet.has(vehicleDestination);

          // Reservation match only applies to trailers
          const reservationMatch = isTrailer
            ? selectedReservations.length === 0 ||
              [...selectedReservationsSet].some((r) =>
                reservationUserName.includes(r)
              )
            : true;

          return (
            customerMatch &&
            carrierMatch &&
            locationMatch &&
            contentMatch &&
            driverMatch &&
            durationMatch &&
            destinationMatch &&
            reservationMatch
          );
        }
      })
    : null;

  // Carrot flip
  useEffect(() => {
    setCarrot(isDriverInfoOpen);
  }, [isDriverInfoOpen]);

  // Driver click
  const handleDriverClick = (row) => {
    onRowSelect(row);
    setSelectedRow(row);
  };

  // Table tab function -----------------------------------------------------
  const active = async (e) => {
    const previous = document.getElementsByClassName("selected");
    for (var i = 0; i < previous.length; ++i) {
      previous[i].className = "tab";
    }
    e.target.className = "selected";
    setTable(e.target.id);
  };

  // time difference for checkin date and current date
  function calculateHoursDifference(timestampString) {
    const timestamp = new Date(timestampString);
    const currentTime = new Date();

    // Calculate the difference in milliseconds
    const differenceInMilliseconds = currentTime - timestamp;

    // Convert milliseconds to hours
    const differenceInHours = differenceInMilliseconds / (1000 * 60 * 60);

    return differenceInHours;
  }

  const toggleRowExpand = (index) => {
    setExpandedRow(expandedRow === index ? null : index);
  };

  return (
    <>
      {data ? (
        <>
          {error && <p className="error">{error}</p>}
          {isModalOpen &&
            user.groups.some((group) =>
              ["Admin", "Safety", "Night Dispatch"].includes(group.name)
            ) && (
              <>
                <div className="page-mask page-mask-animation">
                  <div className="modal-cont2">
                    {!isDocuments && !isEditRow && (
                      <RowClickModal
                        setEditRow={() => setIsEditRow(true)}
                        setDocuments={() => setIsDocumnets(true)}
                        row={selectedRow}
                        close={closeModal}
                        english={english}
                        type={type}
                      />
                    )}
                    {isEditRow && (
                      <EditRowModal
                        row={selectedRow}
                        english={english}
                        close={closeModal}
                        type={type}
                      />
                    )}

                    {isDocuments && (
                      <TableImgModal
                        close={closeModal}
                        info={
                          type.includes("truck")
                            ? selectedRow.truck.truck_number.toString()
                            : type.includes("trailer") ? selectedRow.trailer.trailer_number.toString()
                            : `${selectedRow.visitor.first_name} ${selectedRow.visitor.last_name}`
                        }
                        trailer={selectedRow}
                        english={english}
                        type={type}
                      />
                    )}
                  </div>
                </div>
              </>
            )}
          {isModalOpen &&
            user.groups.some((group) => ["Mechanic"].includes(group.name)) && (
              <>
                <div className="page-mask page-mask-animation">
                  <div className="modal-cont2">
                    <EditCondition
                      english={english}
                      close={closeModal}
                      row={selectedRow}
                      type={type}
                    />
                  </div>
                </div>
              </>
            )}
          {isModalOpen &&
            !user.groups.some((group) =>
              ["Admin", "Safety", "Night Dispatch", "Mechanic"].includes(
                group.name
              )
            ) && (
              <>
                <div className="page-mask page-mask-animation">
                  <div className="modal-cont2">
                    <TableImgModal
                      close={closeModal}
                      info={
                        type === "truck"
                          ? selectedRow.truck.truck_number.toString()
                          : selectedRow.trailer.trailer_number.toString()
                      }
                      trailer={selectedRow}
                      english={english}
                      type={type}
                    />
                  </div>
                </div>
              </>
            )}
          {isConfirmModal && <ActiveConfirmModal row={selectedRow} />}
          {reservationModal && <ReservationConfirm row={selectedReservation} />}
          {type === "driver" && (
            <ul className="yard-tabs">
              <li onClick={active} id="active" className="selected">
                {english("driversTable.tabs.active")}
              </li>
              <li onClick={active} id="deactivated" className="tab">
                {english("driversTable.tabs.deactivated")}
              </li>
            </ul>
          )}

          {type === "reservation" ? (
            <></>
          ) : (
            <>
              {/* <AdvancedSearch endpoint={"assets/backlog-all/trailers/"} /> */}
              <div className="search-filters-cont">
                <form id="filter-form">
                  <div className="search-filters">
                    {filters?.map((filter, index) =>
                      filter === "Contents" || filter === "Carrier" ? (
                        <FancyFilter
                          key={index}
                          id={`${filter}DropDown`}
                          type="list"
                          title={english(
                            `yard-tables.search-filters.dropdowns.${filter.toLowerCase()}`
                          )}
                          items={filterItemsMap[filter]}
                          selectedItems={filterSelectedMap[filter]}
                          handleSelect={
                            (item) =>
                              handleSelect(item, filterSetMap[filter], "list") // Pass the selected item
                          }
                          isOpen={openDropdown === `${filter}DropDown`}
                          toggleDropdown={() =>
                            toggleDropdown(`${filter}DropDown`)
                          }
                        />
                      ) : (
                        <FancyFilter
                          key={index}
                          id={`${filter}DropDown`}
                          type="checkbox"
                          title={english(
                            `yard-tables.search-filters.dropdowns.${filter.toLowerCase()}`
                          )}
                          items={filterItemsMap[filter]}
                          selectedItems={filterSelectedMap[filter]}
                          handleSelect={
                            (item) => handleSelect(item, filterSetMap[filter]) // Pass the selected item
                          }
                          isOpen={openDropdown === `${filter}DropDown`}
                          toggleDropdown={() =>
                            toggleDropdown(`${filter}DropDown`)
                          }
                          english={english}
                        />
                      )
                    )}
                    {type === "reservation" ? <></> : <SearchBar />}
                    {/* Advanced search filter */}
                    {advancedSearch ? (
                      <div>
                        <button
                          className="all-btn other-btn"
                          onClick={openAdvancedSearchForm}
                          type="button"
                        >
                          {english("forms.type.advanced-search.header")}
                        </button>
                      </div>
                    ) : null}
                  </div>
                </form>

                {/* {type.includes("backlog") &&(
                  <PageNavigator
                    page={page}
                    totalPages={totalPages}
                    handleNextPage={handleNextPage}
                    handlePreviousPage={handlePreviousPage}
                    handlePageChange={handlePageChange}
                  />

                  <div>
                    <button>Back</button>
                      number
                    <button>next</button>
                    </div>
                )} */}
              </div>
            </>
          )}
          {advancedSearchForm && (
            <AdvancedSearch
              closeAdvancedSearchForm={closeAdvancedSearchForm}
              vehicleType={type}
              handleAdvancedSearch={handleAdvancedSearch}
            />
          )}
          <div className="table-cont">
            <table id="forwardTable" className="yard-table">
              <thead>
                <tr className="table-header">
                  {/* Map headers here */}
                  {headers?.map((item, index) => (
                    <th key={index} scope="col">
                      <button
                        className="col-header"
                        onClick={() =>
                          handleSort(
                            item === "Duration"
                              ? `${
                                  type.includes(" ") ? type.split(" ")[1] : type
                                }.check_in_dt`
                              : item
                          )
                        }
                      >
                        {renderHeaderContent(item)}
                        <div className="order-filter">
                          <UpArrow />
                          <DownArrow />
                        </div>
                      </button>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {type === "driver"
                  ? // If type is "driver"
                    sortedData(filteredData)
                      ?.filter((item) =>
                        table === "active" ? item.is_active : !item.is_active
                      )
                      .map((item, index) => (
                        <tr
                          key={index}
                          className="data-rows"
                          data-testid={`row-${item.internal_id}`}
                          style={{
                            maxHeight:
                              expandedRow === index ? "700px" : "100px", // Adjust maxHeight to expand or collapse
                          }}
                        >
                          {isMobile && (
                            <td>
                              <button
                                className="all-btn"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  toggleRowExpand(index);
                                }}
                              >
                                Toggle Expand
                              </button>
                            </td>
                          )}
                          {headers.map((header, index) =>
                            header === "first_name" ? (
                              <td
                                data-label={renderHeaderContent(header)}
                                onClick={() => handleDriverClick(item)}
                                key={index}
                                className="gray name cursor-pointer hover"
                              >
                                {selectedRow === item ? (
                                  <>
                                    <Carrot key={index} isOpen={carrot} />{" "}
                                    {renderCellContent(item, header)}
                                  </>
                                ) : (
                                  <>
                                    <Carrot key={index} />{" "}
                                    {renderCellContent(item, header)}
                                  </>
                                )}
                              </td>
                            ) : header === "is_active" ? (
                              <td
                                data-label={renderHeaderContent(header)}
                                key={index}
                                onClick={() => {
                                  setIsConfirmModal(true);
                                  setSelectedRow(item);
                                }}
                                className="gray"
                              >
                                <Switch active={item.is_active} />
                              </td>
                            ) : (
                              <td
                                data-label={renderHeaderContent(header)}
                                key={index}
                                className="gray"
                              >
                                {renderCellContent(item, header)}
                              </td>
                            )
                          )}
                        </tr>
                      ))
                  : type === "reservation"
                  ? sortedData(filteredData)?.map((item, index) => (
                      <tr
                        key={index}
                        style={{
                          maxHeight: expandedRow === index ? "700px" : "100px", // Adjust maxHeight to expand or collapse
                        }}
                        className="data-rows"
                      >
                        {isMobile && (
                          <td>
                            <button
                              className="all-btn"
                              onClick={(e) => {
                                e.stopPropagation();
                                toggleRowExpand(index);
                              }}
                            >
                              Toggle Expand
                            </button>
                          </td>
                        )}
                        {headers.map((header, index) =>
                          header === "Check Out" ? (
                            <td
                              data-label={renderHeaderContent(header)}
                              key={index}
                              className={
                                header === "Check Out" ? "icon-col" : "gray"
                              }
                            >
                              {renderCellContent(item, header)}
                            </td>
                          ) : (
                            <td
                              data-label={renderHeaderContent(header)}
                              key={index}
                              className={
                                header === "Check Out" ? "icon-col" : "gray"
                              }
                            >
                              {renderCellContent(item, header)}
                            </td>
                          )
                        )}
                      </tr>
                    ))
                  : // If type is not "driver"
                    sortedData(filteredData)?.map((item, index) => (
                      <tr
                        key={index}
                        className={
                          calculateHoursDifference(
                            item.trailer
                              ? item.trailer.check_in_dt
                              : item.truck ? item.truck.check_in_dt : item.visitor.check_in_dt
                          ) >= 34
                            ? "duration-exceeded data-rows row-hover"
                            : "data-rows row-hover"
                        }
                        style={{
                          maxHeight: expandedRow === index ? "700px" : "100px", // Adjust maxHeight to expand or collapse
                        }}
                      >
                        {isMobile && (
                          <td>
                            <button
                              className="all-btn"
                              onClick={(e) => {
                                e.stopPropagation();
                                toggleRowExpand(index);
                              }}
                            >
                              Toggle Expand
                            </button>
                          </td>
                        )}
                        {headers.map((header, headerIndex) =>
                          header === "Check Out" ? (
                            <td
                              data-label={renderHeaderContent(header)}
                              key={headerIndex}
                              className={
                                header === "Check Out" ? "icon-col" : "gray"
                              }
                            >
                              {renderCellContent(item, header)}
                            </td>
                          ) : header === "Duration" &&
                            calculateHoursDifference(
                              item.trailer
                                ? item.trailer.check_in_dt
                                : item.truck.check_in_dt
                            ) >= 34 ? (
                            <td
                              data-label={renderHeaderContent(header)}
                              key={headerIndex}
                              onClick={(e) => {
                                e.stopPropagation(); // Prevent row expansion
                                handleRowClick(item); // Open modal
                              }}
                              className={"gray"}
                              style={{
                                color: header === "Duration" ? "#fb6061" : "",
                              }}
                            >
                              {renderCellContent(item, header)}
                            </td>
                          ) : (
                            <>
                              <td
                                data-label={renderHeaderContent(header)}
                                key={headerIndex}
                                onClick={() => {
                                  handleRowClick(item); // Open modal
                                }}
                                className={
                                  header === "Check Out" ? "icon-col" : "gray"
                                }
                              >
                                {renderCellContent(item, header)}
                              </td>
                            </>
                          )
                        )}
                      </tr>
                    ))}
              </tbody>
            </table>
          </div>
        </>
      ) : pageLoading ? (
        <TablePlaceHolder filter={loadingFilters} />
      ) : error ? (
        <p className="error">{error}</p>
      ) : (
        <>
          <p className="info">This table is empty.</p>
        </>
      )}
    </>
  );
}
