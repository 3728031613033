import { useState, useReducer } from "react";
import instance from "../../Interceptor/api_instance";
import { useAuth } from "../../Auth/AuthProvider";
import { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { formatReadableDateTime } from "../Modals/FormatFunctions";
import {
  DownArrow,
  LeftArrowNav,
  RightArrowNav,
  UpArrow,
} from "../../Media/Icons";
import SearchBar from "../Inputs/SearchBar";
import {
  EditVehicleModal,
  VehicleRowClickModal,
} from "../Modals/RowClickModal";

const vehicleCondition = (condition, t) => {
  return (
    <td className="gray hover">
      {condition === "Good" ? (
        <span className="success">
          {t("forms.type.vehicle.placeholders.select-condition.good")}
        </span>
      ) : condition === "Okay" ? (
        <span className="warning">Okay</span>
      ) : condition === "Poor" ? (
        <span className="poor">
          {t("forms.type.vehicle.placeholders.select-condition.poor")}
        </span>
      ) : condition === "Critical" ? (
        <span className="critical">
          {t("forms.type.vehicle.placeholders.select-condition.critical")}
        </span>
      ) : condition === "Under Repair" ? (
        <span className="warning">
          {t("forms.type.vehicle.placeholders.select-condition.under-repair")}
        </span>
      ) : condition === "Waiting parts" ? (
        <span className="warning">
          {t("forms.type.vehicle.placeholders.select-condition.spare-parts")}
        </span>
      ) : condition === "Waiting instructions" ? (
        <span className="warning">
          {t("forms.type.vehicle.placeholders.select-condition.instructions")}
        </span>
      ) : condition === "External repair" ? (
        <span className="warning">
          {t(
            "forms.type.vehicle.placeholders.select-condition.external-repair"
          )}
        </span>
      ) : null}
    </td>
  );
};

const initialState = {
  table: "trailers-table",
  currentTrailerPage: "safety/trailer-repairs",
  currentTruckPage: "safety/truck-repairs",
  currentTrailerPageNum: 1,
  currentTruckPageNum: 1,
  isModalOpen: false,
  selectedVehicle: null,
  vehicleType: null,
  isDocuments: false,
  editRow: false,
  advancedSearch: false,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "SET_CURRENT_TRAILER_PAGE":
      return {
        ...state,
        currentTrailerPage: action.page,
        currentTrailerPageNum: action.pageNum,
      };
    case "SET_CURRENT_TRUCK_PAGE":
      return {
        ...state,
        currentTruckPage: action.page,
        currentTruckPageNum: action.pageNum,
      };
    case "SET_MODAL_OPEN":
      return {
        ...state,
        isModalOpen: true,
        selectedVehicle: action.selectedVehicle,
        vehicleType: action.vehicleType,
      };
    case "SET_MODAL_CLOSE":
      return {
        ...state,
        isModalOpen: false,
        selectedVehicle: null,
        vehicleType: null,
      };
    case "SET_EDIT_ROW_OPEN":
      return {
        ...state,
        editRow: true,
      };
    case "SET_EDIT_ROW_CLOSE":
      return {
        ...state,
        editRow: false,
      };
    case "SET_DOCUMENTS_OPEN":
      return {
        ...state,
        isDocuments: true,
      };
    case "SET_DOCUMENTS_CLOSE":
      return {
        ...state,
        isDocuments: false,
      };
    case "OPEN_ADVANCED_SEARCH":
      return {
        ...state,
        advancedSearch: true,
      };
    case "CLOSE_ADVANCED_SEARCH":
      return {
        ...state,
        advancedSearch: false,
      };
    default:
      return state;
  }
};

export const MechanicBacklog = () => {
  const { t } = useTranslation();
  const [trailerData, setTrailerData] = useState([]);
  const [truckData, setTruckData] = useState([]);
  const [error, setError] = useState(null);
  const [state, dispatch] = useReducer(reducer, initialState);
  const [table, setTable] = useState("trailers-table");
  const [trailerPageLoading, setTrailerPageLoading] = useState(false);
  const [truckPageLoading, setTruckPageLoading] = useState(false);
  const [selectedTrailerRows, setSelectedTrailerRows] = useState([]);
  const [selectedTruckRows, setSelectedTruckRows] = useState([]);
  const { user } = useAuth();

  const truckHeaders = [
    t("yard-tables.table-columns.truck"),
    t("yard-tables.table-columns.condition"),
    t("yard-tables.table-columns.arrival-time"),
    t("yard-tables.table-columns.out"),
  ];

  const trailerHeaders = [
    t("yard-tables.table-columns.trailer"),
    t("yard-tables.table-columns.condition"),
    t("yard-tables.table-columns.arrival-time"),
    t("yard-tables.table-columns.out"),
  ];

  const isAllTrailersSelected =
    selectedTrailerRows.length === trailerData?.results?.length &&
    trailerData?.results?.length > 0;
  const isTrailerIndeterminate =
    selectedTrailerRows.length > 0 &&
    selectedTrailerRows.length < trailerData?.results?.length;

  const isAllTrucksSelected =
    selectedTruckRows.length === truckData?.results?.length &&
    truckData?.results?.length > 0;
  const isTruckIndeterminate =
    selectedTruckRows.length > 0 &&
    selectedTruckRows.length < truckData?.results?.length;

  const fetchData = async (setPageLoading, setdata, currentPage) => {
    try {
      setPageLoading(true);
      const data = await instance.get(currentPage);
      setdata(data.data);
    } catch (error) {
      setError(error);
    } finally {
      setPageLoading(false);
    }
  };

  const handleSelectAll = (setSelectedRows) => (event, data) => {
    const isChecked = event.target.checked;
    if (isChecked) {
      // Select all rows
      setSelectedRows(data);
    } else {
      setSelectedRows([]);
    }
  };

  const handleAdvancedSearch = (filters, type) => {
    const queryString = new URLSearchParams();
    Object.entries(filters).forEach(([key, value]) => {
      if (value !== null) {
        // Exclude null values
        queryString.append(key, value);
      }
    });
    if (type === "backlog trailer") {
      const url = `safety/trailer-repairs?${queryString.toString()}`;
      dispatch({ type: "SET_CURRENT_TRAILER_PAGE", page: url, pageNum: 1 });
    } else if (type === "backlog truck") {
      const url = `safety/truck-repairs?${queryString.toString()}`;
      dispatch({ type: "SET_CURRENT_TRUCK_PAGE", page: url, pageNum: 1 });
    }
  };

  const active = (e) => {
    const previous = document.getElementsByClassName("selected");
    for (var i = 0; i < previous.length; ++i) {
      previous[i].className = "tab";
    }
    e.target.className = "selected";
    setTable(e.target.id);
  };

  const selectCheckbox = (setSelectedRows) => (e, item) => {
    const isChecked = e.target.checked;

    setSelectedRows((prevSelectedRows) => {
      if (isChecked) {
        // Add the item to the selectedRows array
        return [...prevSelectedRows, item];
      } else {
        // Remove the item from the selectedRows array
        return prevSelectedRows.filter((row) => row.id !== item.id);
      }
    });
  };

  useEffect(() => {
    setTruckData(null);
    fetchData(setTruckPageLoading, setTruckData, state.currentTruckPage);
  }, [state.currentTruckPage]);

  useEffect(() => {
    setTrailerData(null);
    fetchData(setTrailerPageLoading, setTrailerData, state.currentTrailerPage);
  }, [state.currentTrailerPage]);

  return (
    <div className="global-cont">
      <Helmet>
        <title>Forward | Repairs</title>
      </Helmet>
      <div className="page-overview">
        <div className="cont-wrapper">
          <div className="inventory-header">
            <h2 className="global-h1 light-black">
              {t("page-headers.backlog")}
            </h2>
          </div>
          {state.isModalOpen && (
            <div className="page-mask page-mask-animation">
              <div className="modal-cont2">
                <VehicleRowClickModal
                  setDocuments={() => dispatch({ type: "SET_DOCUMENTS_OPEN" })}
                  setEditRow={() => dispatch({ type: "SET_EDIT_ROW_OPEN" })}
                  row={state.selectedVehicle}
                  close={() => dispatch({ type: "SET_MODAL_CLOSE" })}
                  english={t}
                  type={state.vehicleType}
                />
              </div>
            </div>
          )}
          {state.editRow && (
            <div className="page-mask page-mask-animation">
              <div className="modal-cont2">
                <EditVehicleModal
                  row={state.selectedVehicle}
                  close={() => dispatch({ type: "SET_EDIT_ROW_CLOSE" })}
                  english={t}
                  type={state.vehicleType}
                />
              </div>
            </div>
          )}
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <ul className="yard-tabs">
              <li className="selected" onClick={active} id="trailers-table">
                {t("yard-tables.tabs.trailers")}
              </li>
              <li className="tab" onClick={active} id="trucks-table">
                {t("yard-tables.tabs.trucks")}
              </li>
            </ul>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
                gap: "16px",
                paddingRight: "16px",
                minHeight: "50px",
              }}
              className="page-select"
            >
              {/* Previous Button for Trailers */}
              {table === "trailers-table" && trailerData?.previous && (
                <LeftArrowNav
                  clickFunction={
                    !trailerPageLoading
                      ? () =>
                          dispatch({
                            type: "SET_CURRENT_TRAILER_PAGE",
                            page: trailerData.previous,
                            pageNum: state.currentTrailerPageNum - 1,
                          })
                      : console.log("stop clicking the button so fast")
                  }
                />
              )}
              {/* Previous Button for Trucks */}
              {table === "trucks-table" && truckData?.previous && (
                <LeftArrowNav
                  clickFunction={
                    !trailerPageLoading
                      ? () =>
                          dispatch({
                            type: "SET_CURRENT_TRAILER_PAGE",
                            page: trailerData.previous,
                            pageNum: state.currentTrailerPageNum - 1,
                          })
                      : console.log("stop clicking the button so fast")
                  }
                />
              )}
              <span>
                {table === "trailers-table" &&
                  state.currentTrailerPageNum > 0 &&
                  state.currentTrailerPageNum}
                {table === "trucks-table" &&
                  state.currentTruckPageNum > 0 &&
                  state.currentTruckPageNum}
                {table === "visitors-table" &&
                  state.currentVisitorPageNum > 0 &&
                  state.currentVisitorPageNum}
              </span>
              {/* Next Button for Trailers */}
              {table === "trailers-table" && trailerData?.next && (
                <RightArrowNav
                  clickFunction={
                    !trailerPageLoading
                      ? () =>
                          dispatch({
                            type: "SET_CURRENT_TRAILER_PAGE",
                            page: trailerData.next,
                            pageNum: state.currentTrailerPageNum + 1,
                          })
                      : console.log("stop clicking the button so fast")
                  }
                />
              )}

              {/* Next Button for Trucks */}
              {table === "trucks-table" && truckData?.next && (
                <RightArrowNav
                  clickFunction={
                    !trailerPageLoading
                      ? () =>
                          dispatch({
                            type: "SET_CURRENT_TRAILER_PAGE",
                            page: trailerData.next,
                            pageNum: state.currentTrailerPageNum + 1,
                          })
                      : console.log("stop clicking the button so fast")
                  }
                />
              )}
            </div>
          </div>
          <div className="search-filters-cont">
            <div className="search-filters">
              <SearchBar />
              <button
                className="change-pwd-btn"
                onClick={() =>
                  handleAdvancedSearch(
                    { status: "Pending" },
                    table === "trailers-table"
                      ? "backlog trailer"
                      : "backlog truck"
                  )
                }
              >
                {t("forms.type.advanced-search.header")}
              </button>
              <button className="all-btn success-btn" type="button">
                {t("buttons.print")}
              </button>
            </div>
          </div>
          <div className="table-cont">
            <table id="forwardTable" className="yard-table">
              <thead>
                <tr className="table-header">
                  {table === "trailers-table" && (
                    <>
                      <th scope="col">
                        <input
                          type="checkbox"
                          onChange={(e) =>
                            handleSelectAll(setSelectedTrailerRows)(
                              e,
                              trailerData.results
                            )
                          }
                          checked={isAllTrailersSelected}
                          ref={(el) => {
                            if (el) el.indeterminate = isTrailerIndeterminate;
                          }} // Handle the indeterminate state
                        />
                      </th>
                      {trailerHeaders.map((header, index) => (
                        <th scope="col">
                          <button className="col-header">
                            {header}
                            <div className="order-filter">
                              <UpArrow />
                              <DownArrow />
                            </div>
                          </button>
                        </th>
                      ))}
                    </>
                  )}
                  {table === "trucks-table" && (
                    <>
                      <th scope="col">
                        <input
                          type="checkbox"
                          onChange={(e) =>
                            handleSelectAll(setSelectedTruckRows)(
                              e,
                              truckData.results
                            )
                          }
                          checked={isAllTrucksSelected}
                          ref={(el) => {
                            if (el) el.indeterminate = isTruckIndeterminate;
                          }} // Handle the indeterminate state
                        />
                      </th>
                      {truckHeaders.map((header, index) => (
                        <th scope="col">
                          <button className="col-header">
                            {header}
                            <div className="order-filter">
                              <UpArrow />
                              <DownArrow />
                            </div>
                          </button>
                        </th>
                      ))}
                    </>
                  )}
                </tr>
              </thead>
              <tbody>
                {table === "trucks-table" && (
                  <>
                    {truckData?.results?.map((item, index) => (
                      <tr className="data-rows row-hover">
                        <td data-label="check" key={index}>
                          <input
                            type="checkbox"
                            onChange={(e) => {
                              selectCheckbox(setSelectedTruckRows)(e, item);
                            }}
                            checked={selectedTruckRows?.some(
                              (selected) => selected.id === item.id
                            )}
                          />
                        </td>
                        <td
                          className="gray hover"
                          onClick={() =>
                            dispatch({
                              type: "SET_MODAL_OPEN",
                              selectedVehicle: item,
                              vehicleType: "truck",
                            })
                          }
                        >
                          {item.carrier_name}-{item.truck_number}
                        </td>
                        {vehicleCondition(item.truck_condition, t)}
                        <td
                          className="gray hover"
                          onClick={() =>
                            dispatch({
                              type: "SET_MODAL_OPEN",
                              selectedVehicle: item,
                              vehicleType: "truck",
                            })
                          }
                        >
                          {formatReadableDateTime(item.check_in_dt)}
                        </td>
                        <td
                          className="gray hover"
                          onClick={() =>
                            dispatch({
                              type: "SET_MODAL_OPEN",
                              selectedVehicle: item,
                              vehicleType: "truck",
                            })
                          }
                        >
                          {formatReadableDateTime(item.check_out_dt)}
                        </td>
                      </tr>
                    ))}
                  </>
                )}
                {table === "trailers-table" && (
                  <>
                    {trailerData?.results?.map((item, index) => (
                      <tr className="data-rows row-hover">
                        <td data-label="check" key={index}>
                          <input
                            type="checkbox"
                            onChange={(e) => {
                              selectCheckbox(setSelectedTrailerRows)(e, item);
                            }}
                            checked={selectedTrailerRows?.some(
                              (selected) => selected.id === item.id
                            )}
                          />
                        </td>
                        <td
                          className="gray hover"
                          onClick={() =>
                            dispatch({
                              type: "SET_MODAL_OPEN",
                              selectedVehicle: item,
                              vehicleType: "trailer",
                            })
                          }
                        >
                          {item.trailer_number}
                        </td>
                        <td
                          className="gray hover"
                          onClick={() =>
                            dispatch({
                              type: "SET_MODAL_OPEN",
                              selectedVehicle: item,
                              vehicleType: "trailer",
                            })
                          }
                        >
                          {item.trailer_condition}
                        </td>
                        <td
                          className="gray hover"
                          onClick={() =>
                            dispatch({
                              type: "SET_MODAL_OPEN",
                              selectedVehicle: item,
                              vehicleType: "trailer",
                            })
                          }
                        >
                          {formatReadableDateTime(item.check_in_dt)}
                        </td>
                        <td
                          className="gray hover"
                          onClick={() =>
                            dispatch({
                              type: "SET_MODAL_OPEN",
                              selectedVehicle: item,
                              vehicleType: "trailer",
                            })
                          }
                        >
                          {formatReadableDateTime(item.check_out_dt)}
                        </td>
                      </tr>
                    ))}
                  </>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};
