import { useEffect, useState } from "react";
import { DownArrow, UpArrow } from "../../Media/Icons";
import { useWindowWidth } from "../Utility/useWindowWidth";
import { MechanicRowModal } from "../Modals/MechanicRowModal";
import { convertToPST } from "../../Helper/FormatFunctions";
import { useAuth } from "../../Auth/AuthProvider";
import { useTranslation } from "react-i18next";
import instance from "../../Interceptor/api_instance";
import SearchBar from "../Inputs/SearchBar";
import { TablePlaceHolder } from "../LoadingAnimations/LoadingAnimations";
import { AdvancedSearchMechanicReports } from "../Forms/AdvancedSearch/MechanicReports";

export default function MechanicMaintenanceReports({}) {
  const { t } = useTranslation();
  const [error, setError] = useState(null);
  const [activityData, setActivityData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [url, setUrl] = useState("safety/get-all-activity");
  const [expandedRow, setExpandedRow] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [advancedSearchForm, setAdvancedSearchForm] = useState(false);
  const [currentPageNum, setCurrentPageNum] = useState(1);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const response = await instance.get(url);
        if (response.status === 200) {
          setActivityData(response.data);
          console.log(response.data);
        }
      } catch (error) {
        setError(error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, [url]);

  const submitSearch = (filters) => {
    const queryString = new URLSearchParams();
    Object.entries(filters).forEach(([key, value]) => {
      if (value !== null) {
        // Exclude null values
        queryString.append(key, value);
      }
    });
    setUrl(`safety/get-all-activity?${queryString}`);
  };

  const handlePageNav = (next) => {
    if (next) {
      setCurrentPageNum(currentPageNum + 1);
      setCurrentPageNum(activityData?.next);
    }
    else {
        setCurrentPageNum(currentPageNum - 1);
        setCurrentPageNum(activityData?.previous);
    }
  };

  // custom hook to calculate window width
  const width = useWindowWidth();
  const isMobile = width <= 500;

  const openRowModal = (row) => {
    setIsModalOpen(true);
    setSelectedRow(row);
  };

  const onClose = () => {
    setIsModalOpen(false);
    setSelectedRow(null);
  };


  const headers = [
    t("yard-tables.table-columns.mechanic"), 
    t("yard-tables.table-columns.datetime"), 
    "Trailer", 
    t("yard-tables.table-columns.truck")
];
  return (
    <>
      {error && <p className="error">{error}</p>}
      {isModalOpen && (
        <div className="page-mask page-mask-animation">
          <div className="modal-cont2">
            <MechanicRowModal english={t} close={onClose} row={selectedRow} />
          </div>
        </div>
      )}
      {advancedSearchForm ? (
        <AdvancedSearchMechanicReports
          english={t}
          closeForm={setAdvancedSearchForm}
          submitForm={submitSearch}
        />
      ) : null}
      {isLoading ?  <TablePlaceHolder/> : <div className="search-filters-cont">
        <form id="filter-form">
          <div className="search-filters">
            <SearchBar />
            <div>
              <button
                className="all-btn other-btn"
                type="button"
                onClick={() => setAdvancedSearchForm(true)}
              >
                {t("forms.type.advanced-search.header")}
              </button>
            </div>
          </div>
        </form>
        {activityData?.previous && (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            onClick={() => handlePageNav(false)}
          >
            <path
              d="M10.5601 15.04L4.16006 8.64C4.00006 8.48 3.92006 8.24 3.92006 8C3.92006 7.76 4.00006 7.52 4.16006 7.36L10.5601 0.960004C10.9601 0.560004 11.5201 0.560004 11.9201 0.960004C12.3201 1.36 12.3201 1.92 11.9201 2.32L6.16006 8.08L11.9201 13.84C12.3201 14.24 12.3201 14.8 11.9201 15.2C11.5201 15.44 10.8801 15.44 10.5601 15.04Z"
              fill="#5656B9"
            />
          </svg>
        )}
        {activityData?.next && (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            onClick={() => handlePageNav(true)}
          >
            <path
              d="M5.44009 0.959997L11.8401 7.36C12.0001 7.52 12.0801 7.76 12.0801 8C12.0801 8.24 12.0001 8.48 11.8401 8.64L5.44009 15.04C5.04009 15.44 4.48009 15.44 4.08009 15.04C3.68009 14.64 3.68009 14.08 4.08009 13.68L9.84009 7.92L4.08009 2.16C3.68009 1.76 3.68009 1.2 4.08009 0.799996C4.48009 0.559997 5.12009 0.559998 5.44009 0.959997Z"
              fill="#5656B9"
            />
          </svg>
        )}
      </div>}
      {activityData ? (
        <>
          <div className="table-cont">
            <table id="forwardTable" className="yard-table">
              <thead>
                <tr className="table-header">
                  {headers?.map((item, index) => (
                    <th key={index} scope="col">
                      <button className="col-header">
                        {item}
                        <div className="order-filter">
                          <UpArrow />
                          <DownArrow />
                        </div>
                      </button>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {activityData.results?.map((item, index) => (
                  <tr
                    key={index}
                    className="data-rows row-hover"
                    data-testid={`row-${item.id}`}
                    onClick={() => openRowModal(item)}
                    style={{
                      maxHeight: expandedRow === index ? "700px" : "100px", // Adjust maxHeight to expand or collapse
                    }}
                  >
                    <td data-label="employee" key={index} className="gray">
                      {item.employee.first_name} {item.employee.last_name}
                    </td>
                    <td data-label="datetime" key={index} className="gray ">
                      {convertToPST(item.created_at)}
                    </td>
                    <td data-label="trailer" key={index} className="gray">
                      {item.trailer ? item.trailer.trailer_number : "None"}
                    </td>
                    <td data-label="truck" key={index} className="gray ">
                      {item.truck
                        ? `${item.truck.carrier_name}-${item.truck.truck_number}`
                        : "None"}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </>
      ) : null}
    </>
  );
}
