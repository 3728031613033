import { useState } from "react";
import { CheckOutVehicle } from "../Forms/CheckOut/CheckOutVehicle";
import "../../CSS/checkInTrailer.css";
import { CheckOutVisitorList } from "../Forms/CheckOut/CheckOutVisitorList";
import { CheckInSuccess } from "../Forms/CheckIn/CheckInSuccess";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import { PageLayout } from "../PageLayout";

export const CheckOut = () => {
  const [checkForm, setForm] = useState("");
  const [message, setMessage] = useState("");
  const [success, setSuccess] = useState(false);
  const { t } = useTranslation();

  const onSuccess = (visitor) => {
    setMessage(
      visitor.first_name + " " + visitor.last_name + " has been checked out."
    );
    setSuccess(true);
    setForm("");
  };

  const handleClose = () => {
    setForm("");
  };

  return (
    <PageLayout title={"Check Out"}>
      <div className="checkin-wrapper">
        <div className="overview-header">
          <h1 className="global-h1 light-black">
            {t("page-headers.check-out")}
          </h1>
        </div>
        <div className="form-cont">
          <h4 className=" global-h4 bottom-space">
            {t("forms.type.check-out-header")}
          </h4>
          <div className="flex-1">
            <button
              onClick={() => setForm("Vehicle")}
              className="check-in-option"
            >
              {t("buttons.vehicle")}
            </button>
            <button
              onClick={() => setForm("Visitor")}
              className="check-in-option"
            >
              {t("buttons.visitor")}
            </button>
          </div>
        </div>

        {checkForm === "Vehicle" ? (
          <div className="page-mask page-mask-animation">
            <CheckOutVehicle
              onClose={handleClose}
              setSuccess={setSuccess}
              english={t}
            />
          </div>
        ) : null}
        {checkForm === "Visitor" ? (
          <div className="page-mask page-mask-animation">
            <CheckOutVisitorList
              onClose={handleClose}
              message={setMessage}
              onSuccess={onSuccess}
              english={t}
            />
          </div>
        ) : null}
        {success ? (
          <div>
            <div className="page-mask page-mask-animation">
              <CheckInSuccess
                title={t("forms.type.success")}
                message={t("forms.type.check-out-success")}
              />
            </div>
          </div>
        ) : null}
      </div>{" "}
    </PageLayout>
  );
};
