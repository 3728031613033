import { useEffect, useState } from "react";
import instance from "../../Interceptor/api_instance";
import SearchBar from "../Inputs/SearchBar";
import { UpArrow, DownArrow } from "../../Media/Icons";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { sortEmployees } from "../Tables/Sorting/functions";

export const EmployeesView = () => {
  const { t } = useTranslation();
  const [data, setData] = useState([]);

  const headers = [
    { name: "Employee", filter: "employeeName" },
    { name: "Title", filter: "jobTitle"},
    { name: "Pin", filter: "pin"}
  ];
  const [order, setOrder] = useState(1);

  const transformData = (data) => {
    const transformedData = data.map((item) => {
      const newData = {
        employeeName: `${item.first_name} ${item.last_name}`,
        jobTitle: item.job_title,
        employeeId: item.employee_pin,
        pin: item.employee_pin,
      };
      return newData;
    });
    transformedData.sort((a, b) => (a.employeeName > b.employeeName ? 1 : -1));
    return transformedData;
  };
  
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await instance.get("safety/get-employees");
        if (response.status === 200) {
          setData(transformData(response.data));
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, []);

  return (
    <div className="global-cont">
      <Helmet>
        <title>Forward | Employees</title>
      </Helmet>
      <div className="page-overview">
        <div className="cont-wrapper">
          <div className="inventory-header">
            <h1 className="global-h1 black">{t("page-headers.employees")}</h1>
          </div>
          <div className="search-filters-cont">
            <SearchBar />
          </div>
          <div className="table-cont">
            <table id="forwardTable" className="yard-table">
              <thead>
                <tr className="table-header">
                  {headers?.map((item, index) => (
                    <th key={index} scope="col">
                      <button onClick={() => sortEmployees()} className="col-header">
                        {item.name}
                        <div className="order-filter">
                          <UpArrow />
                          <DownArrow />
                        </div>
                      </button>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {data?.map((item, index) => (
                  <tr key={index} className="data-rows">
                    <td key={`name-${index}`} className="gray">
                      {item.employeeName}
                    </td>
                    <td key={`position-${index}`} className="gray">
                      {item.jobTitle}
                    </td>
                    <td key={`pin-${index}`} className="gray">
                      {item.pin}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};
