import { useLocation } from "react-router-dom";
import { useAuth } from "../../Auth/AuthProvider";


export const IsRouteActive = (route) => {
    const location = useLocation();
    return location.pathname === route;
}
export const IsUserRole = (roles) => {
    const {user} = useAuth();
    return user.groups.some((group) => roles.includes(group.name));

}