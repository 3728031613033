import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { useState, useEffect, useReducer } from "react";

import UniversalTable from "../Tables/UniversalTable";
import {
  backlogTruckHeaders,
  backlogTrailerHeaders,
  backlogVisitorHeaders,
} from "../Tables/TableHeaders";

import instance from "../../Interceptor/api_instance";
import { PageLayout } from "../PageLayout";

const initialState = {
  table: "trailers-table",
  currentTrailerPage: "assets/backlog/trailers",
  currentTruckPage: "assets/backlog/trucks",
  currentVisitorPage: "assets/backlog/visitors",
  currentTrailerPageNum: 1,
  currentTruckPageNum: 1,
  currentVisitorPageNum: 1,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "SET_CURRENT_TRAILER_PAGE":
      return {
        ...state,
        currentTrailerPage: action.page,
        currentTrailerPageNum: action.pageNum,
      };
    case "SET_CURRENT_TRUCK_PAGE":
      return {
        ...state,
        currentTruckPage: action.page,
        currentTruckPageNum: action.pageNum,
      };
    case "SET_CURRENT_VISITOR_PAGE":
      return {
        ...state,
        currentVisitorPage: action.page,
        currentVisitorPageNum: action.pageNum,
      };
    case "CHANGE_TABLE_TAB":
      return { ...state, table: action.payload };
    default:
      return state;
  }
};
export default function BackLog() {
  const [user, setUser] = useState([]);
  const { t } = useTranslation();
  const [error, setError] = useState(null);
  const [trailerData, setTrailerData] = useState(null);
  const [truckData, setTruckData] = useState(null);
  const [visitorData, setVisitorData] = useState(null);
  const [table, setTable] = useState("trailers-table");

  const [state, dispatch] = useReducer(reducer, initialState);

  const [visitorPageLoading, setVisitorPageLoading] = useState(false);
  const [trailerPageLoading, setTrailerPageLoading] = useState(false);
  const [truckPageLoading, setTruckPageLoading] = useState(false);

  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem("user"));
    setUser(userData);
    setTrailerData(null);
    fetchData(setTrailerPageLoading, setTrailerData, state.currentTrailerPage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.currentTrailerPage]);

  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem("user"));
    setUser(userData);
    setTruckData(null);
    fetchData(setTruckPageLoading, setTruckData, state.currentTruckPage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.currentTruckPage]);

  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem("user"));
    setUser(userData);
    setVisitorData(null);
    fetchData(setVisitorPageLoading, setVisitorData, state.currentVisitorPage);
  }, [state.currentVisitorPage]);

  const fetchData = async (setPageLoading, setData, currentPage) => {
    try {
      setPageLoading(true);
      const data = await instance.get(currentPage);
      setData(data.data);
    } catch (error) {
      setError(error);
    } finally {
      setPageLoading(false);
    }
  };

  const handleAdvancedSearch = (filters, type) => {
    const queryString = new URLSearchParams();
    Object.entries(filters).forEach(([key, value]) => {
      if (value !== null) {
        // Exclude null values
        queryString.append(key, value);
      }
    });
    if (type === "backlog trailer") {
      const url = `assets/backlog/trailers?${queryString.toString()}`;
      dispatch({ type: "SET_CURRENT_TRAILER_PAGE", page: url, pageNum: 1 });
    } else if (type === "backlog truck") {
      const url = `assets/backlog/trucks?${queryString.toString()}`;
      dispatch({ type: "SET_CURRENT_TRUCK_PAGE", page: url, pageNum: 1 });
    } else if (type === "backlog visitor") {
      console.log("Visitors");
      const url = `assets/backlog/visitors?${queryString.toString()}`;
      dispatch({ type: "SET_CURRENT_VISITOR_PAGE", page: url, pageNum: 1 });
    }
  };

  const active = (e) => {
    const previous = document.getElementsByClassName("selected");
    for (let i = 0; i < previous.length; ++i) {
      previous[i].className = "tab";
    }
    e.target.className = "selected";
    setTable(e.target.id);
  };

  return (
    <PageLayout title={"Backlog"}>
      <div className="inventory-header">
        <h2 className="global-h1 light-black">{t("page-headers.backlog")}</h2>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <ul className="yard-tabs">
          <li className="selected" onClick={active} id="trailers-table">
            {t("yard-tables.tabs.trailers")}
          </li>
          <li className="tab" onClick={active} id="trucks-table">
            {t("yard-tables.tabs.trucks")}
          </li>
          <li className="tab" onClick={active} id="visitors-table">
            {t("yard-tables.tabs.visitors")}
          </li>
        </ul>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            gap: "16px",
            paddingRight: "16px",
            minHeight: "50px",
          }}
          className="page-select"
        >
          {/* Previous Button for Trailers */}
          {table === "trailers-table" && trailerData?.previous && (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              onClick={
                !trailerPageLoading
                  ? () =>
                      dispatch({
                        type: "SET_CURRENT_TRAILER_PAGE",
                        page: trailerData.previous,
                        pageNum: state.currentTrailerPageNum - 1,
                      })
                  : console.log("stop clicking the button so fast")
              }
            >
              <path
                d="M10.5601 15.04L4.16006 8.64C4.00006 8.48 3.92006 8.24 3.92006 8C3.92006 7.76 4.00006 7.52 4.16006 7.36L10.5601 0.960004C10.9601 0.560004 11.5201 0.560004 11.9201 0.960004C12.3201 1.36 12.3201 1.92 11.9201 2.32L6.16006 8.08L11.9201 13.84C12.3201 14.24 12.3201 14.8 11.9201 15.2C11.5201 15.44 10.8801 15.44 10.5601 15.04Z"
                fill="#5656B9"
              />
            </svg>
          )}
          {/* Previous Button for Trucks */}
          {table === "trucks-table" && truckData?.previous && (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              onClick={
                !truckPageLoading
                  ? () =>
                      dispatch({
                        type: "SET_CURRENT_TRUCK_PAGE",
                        page: truckData.previous,
                        pageNum: state.currentTruckPageNum - 1,
                      })
                  : console.log("stop clicking the button so fast")
              }
            >
              <path
                d="M10.5601 15.04L4.16006 8.64C4.00006 8.48 3.92006 8.24 3.92006 8C3.92006 7.76 4.00006 7.52 4.16006 7.36L10.5601 0.960004C10.9601 0.560004 11.5201 0.560004 11.9201 0.960004C12.3201 1.36 12.3201 1.92 11.9201 2.32L6.16006 8.08L11.9201 13.84C12.3201 14.24 12.3201 14.8 11.9201 15.2C11.5201 15.44 10.8801 15.44 10.5601 15.04Z"
                fill="#5656B9"
              />
            </svg>
          )}
          {/* Back Button for Visitors */}
          {table === "visitors-table" && visitorData?.previous && (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              onClick={
                !truckPageLoading
                  ? () =>
                      dispatch({
                        type: "SET_CURRENT_VISITOR_PAGE",
                        page: visitorData.previous,
                        pageNum: state.currentVisitorPageNum - 1,
                      })
                  : console.log("stop clicking the button so fast")
              }
            >
              <path
                d="M10.5601 15.04L4.16006 8.64C4.00006 8.48 3.92006 8.24 3.92006 8C3.92006 7.76 4.00006 7.52 4.16006 7.36L10.5601 0.960004C10.9601 0.560004 11.5201 0.560004 11.9201 0.960004C12.3201 1.36 12.3201 1.92 11.9201 2.32L6.16006 8.08L11.9201 13.84C12.3201 14.24 12.3201 14.8 11.9201 15.2C11.5201 15.44 10.8801 15.44 10.5601 15.04Z"
                fill="#5656B9"
              />
            </svg>
          )}
          <span>
            {table === "trailers-table" &&
              state.currentTrailerPageNum > 0 &&
              state.currentTrailerPageNum}
            {table === "trucks-table" &&
              state.currentTruckPageNum > 0 &&
              state.currentTruckPageNum}
            {table === "visitors-table" &&
              state.currentVisitorPageNum > 0 &&
              state.currentVisitorPageNum}
          </span>
          {/* Next Button for Trailers */}
          {table === "trailers-table" && trailerData?.next && (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              onClick={
                !trailerPageLoading
                  ? () =>
                      dispatch({
                        type: "SET_CURRENT_TRAILER_PAGE",
                        page: trailerData.next,
                        pageNum: state.currentTrailerPageNum + 1,
                      })
                  : console.log("stop clicking the button so fast")
              }
            >
              <path
                d="M5.44009 0.959997L11.8401 7.36C12.0001 7.52 12.0801 7.76 12.0801 8C12.0801 8.24 12.0001 8.48 11.8401 8.64L5.44009 15.04C5.04009 15.44 4.48009 15.44 4.08009 15.04C3.68009 14.64 3.68009 14.08 4.08009 13.68L9.84009 7.92L4.08009 2.16C3.68009 1.76 3.68009 1.2 4.08009 0.799996C4.48009 0.559997 5.12009 0.559998 5.44009 0.959997Z"
                fill="#5656B9"
              />
            </svg>
          )}

          {/* Next Button for Trucks */}
          {table === "trucks-table" && truckData?.next && (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              onClick={
                !truckPageLoading
                  ? () =>
                      dispatch({
                        type: "SET_CURRENT_TRUCK_PAGE",
                        page: truckData.next,
                        pageNum: state.currentTruckPageNum + 1,
                      })
                  : console.log("stop clicking the button so fast")
              }
            >
              <path
                d="M5.44009 0.959997L11.8401 7.36C12.0001 7.52 12.0801 7.76 12.0801 8C12.0801 8.24 12.0001 8.48 11.8401 8.64L5.44009 15.04C5.04009 15.44 4.48009 15.44 4.08009 15.04C3.68009 14.64 3.68009 14.08 4.08009 13.68L9.84009 7.92L4.08009 2.16C3.68009 1.76 3.68009 1.2 4.08009 0.799996C4.48009 0.559997 5.12009 0.559998 5.44009 0.959997Z"
                fill="#5656B9"
              />
            </svg>
          )}
          {/* Next Button for Visitors */}
          {table === "visitors-table" && visitorData?.next && (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              onClick={
                !visitorPageLoading
                  ? () =>
                      dispatch({
                        type: "SET_CURRENT_VISITOR_PAGE",
                        page: visitorData.next,
                        pageNum: state.currentVisitorPageNum + 1,
                      })
                  : console.log("stop clicking the button so fast")
              }
            >
              <path
                d="M5.44009 0.959997L11.8401 7.36C12.0001 7.52 12.0801 7.76 12.0801 8C12.0801 8.24 12.0001 8.48 11.8401 8.64L5.44009 15.04C5.04009 15.44 4.48009 15.44 4.08009 15.04C3.68009 14.64 3.68009 14.08 4.08009 13.68L9.84009 7.92L4.08009 2.16C3.68009 1.76 3.68009 1.2 4.08009 0.799996C4.48009 0.559997 5.12009 0.559998 5.44009 0.959997Z"
                fill="#5656B9"
              />
            </svg>
          )}
        </div>
      </div>

      {table === "trailers-table" && (
        <UniversalTable
          english={t}
          data={trailerData?.results}
          headers={backlogTrailerHeaders}
          filters={[]}
          advancedSearch={true}
          handleAdvancedSearch={handleAdvancedSearch}
          type="backlog trailer"
          error={error}
          page={state.currentTrailerPage}
          user={user}
          pageLoading={trailerPageLoading}
        />
      )}
      {table === "trucks-table" && (
        <UniversalTable
          english={t}
          data={truckData?.results}
          headers={backlogTruckHeaders}
          filters={[]}
          advancedSearch={true}
          handleAdvancedSearch={handleAdvancedSearch}
          type="backlog truck"
          error={error}
          page={state.currentTruckPage}
          user={user}
          pageLoading={truckPageLoading}
        />
      )}
      {table === "visitors-table" && (
        <UniversalTable
          english={t}
          data={visitorData?.results}
          headers={backlogVisitorHeaders}
          filters={[]}
          advancedSearch={true}
          handleAdvancedSearch={handleAdvancedSearch}
          type="backlog visitor"
          error={error}
          page={state.currentVisitorPage}
          user={user}
          pageLoading={visitorPageLoading}
        />
      )}
    </PageLayout>
  );
}
