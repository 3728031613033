import { MobileSubNavRoute, SubNavRoute } from "./SubNavRoute";
import React from "react";
import { IsRouteActive } from "./NavFunctions";

export const NavCategory = ({
  item,
  setOpenCategory,
  openCategory,
  user,
}) => {
  return (
    <>
      <li
        className={`menu-item ${
          openCategory === item.category
            ? "active-span active"
            : user.theme === "System"
            ? "s-inactive inactive"
            : "a-inactive inactive"
        }`}
        onClick={() => setOpenCategory(item.category)}
      >
        <div className="menu-category menu-li">
          {React.cloneElement(item.icon, {
            color: item.routes.some((route) => IsRouteActive(route.url))
              ? "white"
              : user.theme === "System"
              ? "#CFBEFF"
              : "#2EBCEE",
          })}{" "}
          <span style={{color: item.routes.some((route) => IsRouteActive(route.url))
              ? "white"
              : user.theme === "System"
              ? "#CFBEFF"
              : "#2EBCEE"}}>{item.category}</span>
        </div>
        <ul
          className={
            openCategory === item.category ? "sub-menu expanded" : "sub-menu"
          }
        >
          <div>
            {item.routes.map((route, index) => (
              <SubNavRoute
                key={index}
                route={route.url}
                text={route.text}
                user={user}
                setOpenCategory={() => setOpenCategory(item.category)}
              />
            ))}
          </div>
        </ul>
      </li>
    </>
  );
};

export const MobileNavCategory = ({
  item,
  setOpenCategory,
  openCategory,
  user,
  isSideOpen
}) => {
    console.log(item, openCategory)
  return (
    <>
      <li
        className={`menu-item mobile ${
            openCategory === item.category
              ? "active-span active"
              : user.theme === "System"
              ? "s-inactive inactive"
              : "a-inactive inactive"
          }`}
        onClick={() => setOpenCategory(item.category)}
      >
        <div className="menu-category menu-li">
          {React.cloneElement(item.icon, {
            color: item.routes.some((route) => IsRouteActive(route.url))
              ? "white"
              : user.theme === "System"
              ? "#CFBEFF"
              : "#2EBCEE",
          })}{" "}
          {isSideOpen ? <span>{item.category}</span> : null}
        </div>
        <ul
          className={
            openCategory === item.category
              ? "mobile-sub-menu expanded"
              : "mobile-sub-menu"
          }
        >
            <div>
                {item.routes.map((route, index) => (
                    <MobileSubNavRoute key={index}
                    route={route.url}
                    text={route.text}
                    user={user}
                    setOpenCategory={() => setOpenCategory(item.category)}/>
                ))}
            </div>
        </ul>
      </li>
    </>
  );
};
