import { useTranslation } from "react-i18next";
import MechanicMaintenanceReports from "../Tables/MechanicMaintenanceReports";
import { PageLayout } from "../PageLayout";

export const MechanicReportsView = () => {
  const { t } = useTranslation();

  return (
    <PageLayout title={"Repairs"}>
      <div className="inventory-header">
        <h2 className="light-black global-h2">{t("page-headers.reports")}</h2>
      </div>
      <MechanicMaintenanceReports />
    </PageLayout>
  );
};
