import { Link } from "react-router-dom";
import React from "react";
import { IsRouteActive } from "./NavFunctions";
import { useAuth } from "../../Auth/AuthProvider";
import { useWindowWidth } from "../Utility/useWindowWidth";

export const NavRoute = ({
  icon,
  route,
  text,
  user,
  setOpenCategory,
}) => {
    const {openSideBar} = useAuth();
    const width = useWindowWidth();
    
  return (
    <>
      <li className="menu-item menu-category menu-li single-route" onClick={setOpenCategory}>
        <Link to={route}>
          {React.cloneElement(icon, {
            color: IsRouteActive(route)
              ? "white"
              : user.theme === "System"
              ? "#CFBEFF"
              : "#2EBCEE",
          })}
          <span
            className={
              IsRouteActive(route)
                ? "active-span"
                : user.theme === "System"
                ? "s-inactive inactive"
                : "a-inactive inactive"
            }
          >
            {text}
          </span>
        </Link>
      </li>
    </>
  );
};

