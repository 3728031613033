export const formatReadableDateTime = (isoString) => {
    const date = new Date(isoString);
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
      timeZone: "America/Los_Angeles",
    };
    return new Intl.DateTimeFormat("es-ES", options).format(date);
  };

export const formatReadableDate = (isoString) => {
  const date = new Date(isoString);
  const options = {
    year: "numeric",
    month: "long",
    day: "numeric",
    timeZone: "America/Los_Angeles",
  };
  return new Intl.DateTimeFormat("es-ES", options).format(date);
};

export const formatReadableTime = (isoString) => {
  const date = new Date(isoString);
  const options = {
    hour: "numeric",
    minute: "numeric",
    hour12: true,
    timeZone: "America/Los_Angeles",
  };
  return new Intl.DateTimeFormat("es-ES", options).format(date)
}