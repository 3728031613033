import instance from "../../Interceptor/api_instance";
import { CheckInTrailerF15 } from "../Forms/CheckIn/CheckInTrailerF15";
import { CheckInTruckF15 } from "../Forms/CheckIn/CheckInTruckF15";
import { SpinnerOverlay } from "../LoadingAnimations/SpinnerOverlay";
import { populateF15, loadPdfTemplate } from "../Utility/PopulateForms";
import { useEffect, useState } from "react";
import { ResponseStatus } from "./EditCondition";
import { Spinner } from "../LoadingAnimations/LoadingAnimations";

const PreviewF15 = ({ report, setPreviewModal }) => {
  const [blobUrl, setBlobUrl] = useState("");
  useEffect(() => {
    const fetchPdf = async () => {
      try {
        const pdfFile = "F15-2024.pdf";
        const pdfTemplate = await loadPdfTemplate(pdfFile);
        const filledPdfBytes = await populateF15(pdfTemplate, report);

        // Convert filledPdfBytes to a Blob
        const blob = new Blob([filledPdfBytes], { type: "application/pdf" });

        // Create a Blob URL
        setBlobUrl(URL.createObjectURL(blob));

        return blobUrl;
      } catch (error) {
        window.alert(error);
        setPreviewModal(false);
      }
    };
    fetchPdf();
  }, []);

  return (
    <>
      {blobUrl ? (
        <div className="page-mask page-mask-animation">
          <div>
            <iframe src={blobUrl} title="PDF Preview"></iframe>
            <button onClick={() => setPreviewModal(false)}>Close</button>
          </div>
        </div>
      ) : (
        <SpinnerOverlay />
      )}
    </>
  );
};

const EditF15 = ({ report, setEditModal, english }) => {
  const [currentForm, setCurrentForm] = useState(1);
  const [truckForm, setTruckForm] = useState(report.f15_truck);
  const [trailerForm, setTrailerForm] = useState(report.f15_trailer);
  const [submitRequest, setSubmitRequest] = useState(false);
  const [message, setMessage] = useState(null);
  const [responseModal, setResponseModal] = useState(false);
  const [status, setStatus] = useState(null);

  const nextForm = () => {
    if (trailerForm && currentForm === 1) {
      setCurrentForm(currentForm + 1);
    } else {
      setCurrentForm(3);
    }
  };

  const backForm = () => {
    if (trailerForm && currentForm === 3) {
      setCurrentForm(currentForm - 1);
    } else {
      setCurrentForm(1);
    }
  };

  const close = () => {
    setEditModal(false);
  };
  const handleTruckChange = (key, field, value) => {
    if (key === "anomalies") {
      setTruckForm((prevForm) => ({
        ...prevForm,
        anomalies: value,
      }));
    } else {
      setTruckForm((prevForm) => ({
        ...prevForm,
        truck_inspection: {
          ...prevForm.truck_inspection,
          [key]: {
            ...prevForm.truck_inspection[key],
            [field]: value,
          },
        },
      }));
    }
  };

  const handleTrailerChange = (key, field, value) => {
    if (key === "seal_tests") {
      setTrailerForm((prevForm) => ({
        ...prevForm,
        seal_tests: {
          ...prevForm.seal_tests,
          [field]: value,
        },
      }));
    } else if (key === "anomalies") {
      setTrailerForm((prevForm) => ({
        ...prevForm,
        anomalies: value,
      }));
    } else {
      setTrailerForm((prevForm) => ({
        ...prevForm,
        trailer_inspection: {
          ...prevForm.trailer_inspection,
          [key]: {
            ...prevForm.trailer_inspection[key],
            [field]: value,
          },
        },
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setSubmitRequest(true);
      const data = {
        ...report,
        f15_truck: {
          ...truckForm,
        },
        f15_trailer: {
          ...trailerForm,
        },
      };
      console.log(data);
      const response = await instance.put("safety/edit-f15", data);
      if (response.status === 200) {
        setMessage("La modificación fue exitosa.");
        setStatus("Éxito");
      }
    } catch (error) {
      setMessage(error);
      setStatus("Error");
    } finally {
      setResponseModal(true);
      setSubmitRequest(false);
    }
  };

  return (
    <>
      {currentForm === 1 && (
        <div className="page-mask">
          <CheckInTruckF15
            nextForm={nextForm}
            backForm={close}
            english={english}
            handleTruckChange={handleTruckChange}
            truckForm={truckForm}
            inOrOut={report.check_in_dt ? true : false}
          />
        </div>
      )}
      {currentForm === 2 && (
        <div className="page-mask">
          <CheckInTrailerF15
            backForm={backForm}
            nextForm={nextForm}
            english={english}
            handleTrailerChange={handleTrailerChange}
            trailerForm={trailerForm}
            sealStatus={report.trailer.is_seal}
            inOrOut={report.check_in_dt ? true : false}
          />
        </div>
      )}
      {currentForm === 3 && (
        <div className="page-mask">
          <div className="blank-card active-confirm">
            <h4>{english("buttons.confirm")}</h4>
            <p className="gray mb-4">{english("forms.type.f15.edit.review")}</p>
            {submitRequest ? (
              <Spinner />
            ) : (
              <div className="d-flex gap-2 justify-content-center align-items-center full-cont">
                <button className="change-pwd-btn" onClick={handleSubmit}>
                  {english("buttons.submit")}
                </button>
                <button id="back-btn" onClick={backForm} className="cancel-btn">
                  {english("buttons.back")}
                </button>
              </div>
            )}
          </div>
        </div>
      )}
      {responseModal && (
        <ResponseStatus
          message={message}
          status={status}
          close={setResponseModal}
        />
      )}
    </>
  );
};

export const RowClickF15Modal = ({ report, close, english }) => {
  const [previewModal, setPreviewModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  return (
    <>
      {editModal && (
        <EditF15
          report={report}
          setEditModal={setEditModal}
          english={english}
        />
      )}
      {previewModal && (
        <PreviewF15 report={report} setPreviewModal={setPreviewModal} />
      )}
      <div className="edit-modal">
        <div>
          <h4 data-testid={"documents"} className="global-h2">
            {report.truck.carrier_name}-{report.truck.truck_number}{" "}
            {report.trailer ? report.trailer.trailer_number : ""}
          </h4>
          <div style={{ flexGrow: "1" }}></div>
        </div>
        <div style={{ margin: "auto" }}>
          <button
            className="other-btn gap"
            onClick={() => setPreviewModal(true)}
          >
            {english("buttons.preview")}
          </button>
          <button className="other-btn" onClick={() => setEditModal(true)}>
            {english("buttons.edit-row")}
          </button>
        </div>
        <div className="btn-cont">
          <button onClick={close} className="close-btn">
            {english("buttons.close")}
          </button>
        </div>
      </div>
    </>
  );
};