import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { useAuth } from "../../Auth/AuthProvider";
import "../../CSS/navbar.css";
import { useTranslation } from "react-i18next";
import {
  BacklogIcon,
  BacklogIcon2,
  CheckInIcon,
  CheckOutIcon,
  DriverWheel,
  LogoutIcon,
  OpenSidebarIcon,
  OverviewIcon,
  ReportsIcon,
  SettingsIcon,
} from "../../Media/Icons";
import { Logo } from "../../Media/logos";
import { MobileNavCategory, NavCategory } from "./Category";
import { NavRoute } from "./NavRoute";
import { useWindowWidth } from "../Utility/useWindowWidth";
import { IsUserRole } from "./NavFunctions";

export const NewSideBar2 = () => {
  const [isAuth, setIsAuth] = useState(false);
  const { user, isSideOpen, openSideBar } = useAuth();
  const [openCategory, setOpenCategory] = useState("");
  const [filteredRoutes, setFilteredRoutes] = useState([]);
  const width = useWindowWidth();

  const isTablet = width <= 1300 && width > 500;
  const isMobile = width <= 500;

  const { t } = useTranslation();

  const userHasAccess = (roles, userGroups) => {
    return roles.some((role) =>
      userGroups.some((group) => group.name === role)
    );
  };

  const navRoutes = [
    {
      category: t("page-headers.overview"),
      roles: ["Admin", "Guard", "Guard Supervisor", "Safety", "Dispatch", "Mechanic"],
      icon: <OverviewIcon />,
      routes: [
        {
          url: "/",
          roles: ["Admin", "Guard", "Guard Supervisor", "Dispatch", "Safety", "Mechanic"],
          text: t("page-headers.inventory"),
        },
        {
          url: "/reservations",
          roles: ["Admin", "Dispatch"],
          text: t("page-headers.reservations"),
        },
      ],
    },
    {
      category: t("page-headers.check-in-out"),
      roles: ["Admin", "Guard", "Guard Supervisor", "Safety"],
      icon: <CheckInIcon />,
      routes: [
        {
          url: "/check-in-trailer",
          roles: ["Admin", "Guard", "Safety", "Guard Supervisor"],
          text: t("page-headers.check-in"),
        },
        {
          url: "/check-out",
          roles: ["Admin", "Guard", "Guard Supervisor", "Safety"],
          text: t("page-headers.check-out"),
        },
      ],
    },

    {
      category: t("page-headers.reports"),
      roles: ["Admin", "Safety", "Mechanic"],
      icon: <ReportsIcon />,
      routes: [
        {
          url: "/ctpat-reports",
          roles: ["Admin", "Safety"],
          text: "C-TPAT",
        },
        {
          url: "/repairs-backlog",
          roles: ["Admin", "Safety", "Mechanic"],
          text: t("page-headers.repairs")
        }
      ],
    },
    {
      category: t("page-headers.employees"),
      roles: ["Admin", "Safety"],
      icon: <DriverWheel />,
      routes: [
        {
          url: "/driving-team",
          roles: ["Admin", "Safety"],
          text: t("page-headers.driving-team"),
        },
        {
          url: "/new-driver",
          roles: ["Admin", "Safety"],
          text: t("page-headers.newDriver"),
        },
        {
          url: "/new-employee",
          roles: ["Admin", "Safety"],
          text: t("page-headers.new-employee"),
        },
      ],
    },
    {
      category: t("page-headers.backlog"),
      roles: ["Admin", "Safety", "Dispatch"],
      icon: <BacklogIcon2/>,
      routes: [
        {
          url: "/backlog",
          roles: ["Admin", "Safety", "Dispatch"],
          text: t("page-headers.backlog")
        }
      ]
    }
  ];

  const filterNavRoutes = (navRoutes, user) => {
    return navRoutes
      .filter((category) => userHasAccess(category.roles, user.groups)) // Filter categories
      .map((category) => ({
        ...category,
        routes: category.routes.filter((route) =>
          userHasAccess(route.roles, user.groups)
        ), // Filter routes within the category
      }));
  };

  const handleOpenCategory = (category) => {
    if (openCategory === category) {
      setOpenCategory("");
    } else {
      setOpenCategory(category);
    }
  };

  useEffect(() => {
    try {
      if (user !== null) {
        setIsAuth(true);
        setFilteredRoutes(filterNavRoutes(navRoutes, user));
      } else {
        setIsAuth(false);
      }
    } catch (e) {
      window.alert(`Error occured loading: ${e}`);
    }
  }, [user]);

  return (
    <>
      {isAuth && user ? (
        isTablet ? (
          <div
            id="sidebar"
            className={`sidebar${
              isSideOpen ? "-active" : ""
            } sidebar-${user.theme.toLowerCase()}`}
          >
            <div className="sidebar-header">
              <a className="logo-a" id="logo-a" href="/">
                <Logo
                  fillColor={user.theme === "System" ? "#FFFF" : "#D43732"}
                />
                <h4 className="sidebar-name">Forward</h4>
              </a>
              <div onClick={openSideBar}>
                <OpenSidebarIcon
                  color={user.theme === "System" ? "#CFBEFF" : "#2EBCEE"}
                />
              </div>
            </div>
            <div className="side-menu">
              <ul className="menu">
                {filteredRoutes.map((item) =>
                  IsUserRole(item.roles) ? (
                    isSideOpen ? (
                      <NavCategory
                        item={item}
                        setOpenCategory={handleOpenCategory}
                        openCategory={openCategory}
                        user={user}
                      />
                    ) : (
                      <MobileNavCategory
                        item={item}
                        setOpenCategory={handleOpenCategory}
                        openCategory={openCategory}
                        user={user}
                        isSideOpen={isSideOpen}
                      />
                    )
                  ) : null
                )}

              </ul>
            </div>
            <div className="side-menu logout">
              <ul className="menu">
                <NavRoute
                  setOpenCategory={() => setOpenCategory("")}
                  icon={<SettingsIcon />}
                  route={"/settings"}
                  text={t("page-headers.settings")}
                  user={user}
                />
                <NavRoute
                  setOpenCategory={() => setOpenCategory("")}
                  icon={<LogoutIcon />}
                  route={"/logout"}
                  text={t("page-headers.logout")}
                  user={user}
                />
              </ul>
            </div>
          </div>
        ) : isMobile ? (
          <div
            id="sidebar"
            className={`sidebar${
              isSideOpen ? "-active mobile open" : "-active mobile close"
            } sidebar-${user.theme.toLowerCase()}`}
          >
            <div className="sidebar-header">
              <a className="logo-a" id="logo-a" href="/">
                <Logo
                  fillColor={user.theme === "System" ? "#FFFF" : "#D43732"}
                />
                <h4 className="sidebar-name">Forward</h4>
              </a>
              <div onClick={openSideBar}>
                {isMobile ? (
                  <CheckOutIcon
                    color={user.theme === "System" ? "#CFBEFF" : "#2EBCEE"}
                  />
                ) : (
                  <OpenSidebarIcon
                    color={user.theme === "System" ? "#CFBEFF" : "#2EBCEE"}
                  />
                )}
              </div>
            </div>
            <div className="side-menu">
              <ul className="menu">
                {filteredRoutes.map((item, index) =>
                  IsUserRole(item.roles) ? (
                    <NavCategory
                      item={item}
                      setOpenCategory={handleOpenCategory}
                      openCategory={openCategory}
                      user={user}
                    />
                  ) : null
                )}

              </ul>
            </div>
            <div className="side-menu logout">
              <ul className="menu">
                <NavRoute
                  setOpenCategory={() => setOpenCategory("")}
                  icon={<SettingsIcon />}
                  route={"/settings"}
                  text={t("page-headers.settings")}
                  user={user}
                />
                <NavRoute
                  setOpenCategory={() => setOpenCategory("")}
                  icon={<LogoutIcon />}
                  route={"/logout"}
                  text={t("page-headers.logout")}
                  user={user}
                />
              </ul>
            </div>
          </div>
        ) : (
          <div
            id="sidebar"
            className={`sidebar sidebar-${user.theme.toLowerCase()}`}
          >
            <div className="sidebar-header">
              <a className="logo-a" id="logo-a" href="/">
                <Logo
                  fillColor={user.theme === "System" ? "#FFFF" : "#D43732"}
                />
                <h4 className="sidebar-name">Forward</h4>
              </a>
              <div onClick={openSideBar}>
                <OpenSidebarIcon
                  color={user.theme === "System" ? "#CFBEFF" : "#2EBCEE"}
                />
              </div>
            </div>
            <div className="side-menu">
              <ul className="menu">
                {filteredRoutes.map((item, index) =>
                  IsUserRole(item.roles) ? (
                    <NavCategory
                      item={item}
                      setOpenCategory={handleOpenCategory}
                      openCategory={openCategory}
                      user={user}
                    />
                  ) : null
                )}
              </ul>
            </div>
            <div className="side-menu logout">
              <ul className="menu">
                <NavRoute
                  setOpenCategory={() => setOpenCategory("")}
                  icon={<SettingsIcon />}
                  route={"/settings"}
                  text={t("page-headers.settings")}
                  user={user}
                />
                <NavRoute
                  setOpenCategory={() => setOpenCategory("")}
                  icon={<LogoutIcon />}
                  route={"/logout"}
                  text={t("page-headers.logout")}
                  user={user}
                />
              </ul>
            </div>
          </div>
        )
      ) : null}
    </>
  );
};
